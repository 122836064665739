import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { usePagination } from '@hooks/usePagination';
import { useRequestBody } from '@hooks/useRequestBody';
import {
  getCloudInstanceResourceList,
  buildCloudInstanceResource,
  destroyCloudInstanceResource,
  startCloudInstanceResource,
  stopCloudInstanceResource,
  buildAndStartCloudInstanceResource,
} from '@store/cloud/cloud.service';

import { CloudInstanceResource } from '@store/cloud/contracts';
import { ActionButtons } from './components/ActionButtons/ActionButtons';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import styles from './InstanceResourceForm.module.scss';
import { useMediaQuery, useTheme } from '@mui/material';
import css from '@pages/teams/TeamMyVacancies/TeamMyVacancies.module.scss';
import { ActionsModalButton } from '@components/MUI/ActionsModalButton/ActionsModalButton';
import { Button } from '@components/MUI/Button';

const InstanceResources = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const dispatch = useAppDispatch();
  const [InstanceResources, setInstanceResources] = useState<CloudInstanceResource[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const {
    data: fetchedData,
    total,
    loading,
  } = usePagination({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    accumulate: false,
    action: (query) => getCloudInstanceResourceList(query),
    additionalParams: requestBody,
  });

  useEffect(() => {
    setInstanceResources(fetchedData);
  }, [dispatch, fetchedData]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className="col">
      <div className={styles.adaptiveTableMUI}>
        <div className={styles.mainFilters}>
          Фильтры:
          <div className={styles.filters}>
            <Button className={styles.filterBtn}>Активные ВМ</Button>
            <Button className={styles.filterBtn}>Неактивные ВМ</Button>
          </div>
        </div>
        <AdaptiveTableMUI
          emptyLabel="У вас нет виртуальных машин"
          setRequestBody={(value) => setRequestBody(value)}
          setSortColumn={setSortColumn}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          isLoading={loading}
          columns={{
            actionsRaw: {
              checkboxInput: true,
              children: () => (
                <div className={styles.checkboxContainer}>
                  <input type="checkbox" className={styles.checkboxBtn} />
                </div>
              ),
            },
            name: {
              label: 'Название',
              filterInput: true,
              children: (value, row) => <NavLink to={`/clouds/${row.id}`}>{value}</NavLink>,
            },
            infrastructure: {
              label: 'Инфраструктура',
              filterInput: true,
              children: (value, row) => row.infrastructure.name,
            },
            actions: {
              label: '',
              children: (value, row) =>
                !isTablet ? (
                  <ActionButtons
                    handleBuild={buildCloudInstanceResource}
                    handleDestroy={destroyCloudInstanceResource}
                    handleBuildAndStart={buildAndStartCloudInstanceResource}
                    handleStart={startCloudInstanceResource}
                    handleStop={stopCloudInstanceResource}
                    objectId={row.id}
                  />
                ) : (
                  <ActionsModalButton
                    children={() => (
                      <div className={css.modal}>
                        <button
                          disabled
                          onClick={() => alert('недоступно')}
                          className={css.modal__button}>
                          Сделать выбор (в разработке...)
                        </button>
                        <hr className={styles.hr} />
                        <button
                          disabled
                          style={{ cursor: 'not-allowed' }}
                          onClick={() => alert('недоступно')}
                          className={css.modal__button}>
                          Выбор (в разработке...)
                        </button>
                        <hr className={styles.hr} />
                        <button
                          style={{ cursor: 'not-allowed' }}
                          disabled
                          onClick={() => alert('недоступно')}
                          className={css.modal__button}>
                          Включить выбранные (в разработке...)
                        </button>
                        <hr className={styles.hr} />
                        <button
                          style={{ cursor: 'not-allowed' }}
                          disabled
                          onClick={() => alert('недоступно')}
                          className={css.modal__button}>
                          Включить текущую ВМ (в разработке...)
                        </button>
                        <hr className={styles.hr} />
                        <button
                          style={{ cursor: 'not-allowed' }}
                          disabled
                          onClick={() => alert('недоступно')}
                          className={css.modal__button}>
                          Выключить текущую ВМ (в разработке...)
                        </button>
                      </div>
                    )}
                  />
                ),
            },
          }}
          rows={InstanceResources}>
          <TablePaginationMUI
            limit={limit}
            count={total}
            entityLabel="машин"
            page={page}
            handleChangePage={(_, newPage) => setPage(newPage)}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </AdaptiveTableMUI>
      </div>
    </div>
  );
};

export { InstanceResources };
