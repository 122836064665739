import { LabelDisplayedRowsArgs } from '@mui/base';
import styles from './Table.module.scss';
import { ReactComponent as ArrowLeftIcon } from '@assets/img/arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/img/arrowRight.svg';
import { Pagination } from '@mui/material';
import { PaginationItem } from '@components/MUI/PaginationItem';
import { TablePagination } from '@components/MUI/TablePagination';
import React, { useMemo } from 'react';

type PaginationProps = {
  count?: number;
  page: number;
  limit: number;
  entityLabel: string | undefined;
  withoutChangingItemsCount?: boolean;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => void | undefined;
  handleChangeRowsPerPage: (newLimit: number) => void;
};
type CustomPaginationActionsProps = {
  page: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => void | undefined;
  count?: number;
  rowsPerPage?: number;
};
export const TablePaginationMUI = ({
  count,
  entityLabel,
  limit,
  page,
  withoutChangingItemsCount,
  handleChangePage,
  handleChangeRowsPerPage,
}: PaginationProps) => {
  const totalPages = useMemo(() => {
    return count && count > 0 ? Math.ceil(count / limit) : 1;
  }, [count, limit]);

  const TablePaginationActions = ({ page }: CustomPaginationActionsProps) => {
    return (
      <div className={styles.tablePaginationActions}>
        <Pagination
          count={totalPages}
          defaultPage={page}
          page={page}
          siblingCount={1}
          shape="rounded"
          onChange={handleChangePage}
          renderItem={(item) => (
            <PaginationItem
              className={styles.paginationItem}
              components={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
              {...item}
            />
          )}
        />
      </div>
    );
  };

  const labelDisplayedRows = ({ count, page }: LabelDisplayedRowsArgs) => {
    const correctInput = (page: number) => {
      const totalPage = Math.ceil(count / limit);
      if (page > totalPage) {
        handleChangePage(null, totalPage);
      } else if (page <= 0 || Number.isNaN(page)) {
        handleChangePage(null, 1);
      } else handleChangePage(null, page);
    };

    return (
      <div className={styles.paginationActions}>
        <span>Перейти на страницу</span>
        <input
          type="number"
          placeholder="1"
          className={styles.paginationPageInput}
          onChange={(e) => correctInput(parseInt(e.target.value, 10))}
        />
        <span>из {totalPages} </span>
        <TablePaginationActions
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          count={count}
        />
      </div>
    );
  };

  return (
    <TablePagination
      rowsPerPageOptions={
        withoutChangingItemsCount
          ? []
          : [
              { label: `10 ${entityLabel}`, value: 10 },
              { label: `30 ${entityLabel}`, value: 30 },
              { label: `50 ${entityLabel}`, value: 50 },
            ]
      }
      colSpan={3}
      count={count ? count : 0}
      rowsPerPage={limit}
      page={page}
      slotProps={{
        select: {
          name: 'Показать',
          className: styles.select,
        },
        root: {
          className: styles.paginationWrapper,
        },
        displayedRows: {
          className: styles.displayedRows,
        },
        selectLabel: {
          className: styles.selectLabel,
        },
        actions: {
          className: styles.actions,
        },
      }}
      slots={{
        root: 'div',
        displayedRows: 'div',
      }}
      labelDisplayedRows={labelDisplayedRows}
      onPageChange={handleChangePage}
      labelRowsPerPage={'Показать'}
      onRowsPerPageChange={(e) => handleChangeRowsPerPage(parseInt(e.target.value))}
    />
  );
};
