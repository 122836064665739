import { ApiClient } from '@store/api-client';
import { PaginationQuery } from '@store/common';
import {
  AnalyticsProjectsResponse,
  AnalyticsRequestsResponse,
  AnalyticsVananciesResponse,
  RequestBodyType,
  TimeProjectAnalyticsResponse,
  releases,
} from './contracts';
import dayjs from 'dayjs';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';

export type listAnalyticsRequestsType = PaginationQuery & RequestBodyType;

export const getTimeAnalytics = async (
  requestBody: Api.operations['getDashboardTimeAnalytics']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getDashboardTimeAnalytics']['responses']['200']['content']['application/json']
  >('/api/dashboard/time/analytics', requestBody);
  return plainToInstance(TimeProjectAnalyticsResponse, data);
};

export const getTaskStatuses = async (
  requestBody: Api.operations['getDashboardTasks']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getDashboardTasks']['responses']['200']['content']['application/json']
  >('/api/dashboard/status/task', requestBody);
  return data;
};

export const getProjectStatuses = async () => {
  const { data } = await ApiClient.post<
    Api.operations['getDashboardProjects']['responses']['200']['content']['application/json']
  >('/api/dashboard/status/project');
  return data;
};

export const listAnalyticsRequests = async (request: listAnalyticsRequestsType) => {
  const URI = '/api/dashboard/request';
  const { data } = await ApiClient.post<AnalyticsRequestsResponse>(
    URI,
    {
      ...request,
    },
    {
      signal: request.signal,
    },
  );
  return data;
};

export const listProjectsRequests = async (request: PaginationQuery) => {
  const URI = '/api/dashboard/project';
  const { data } = await ApiClient.post<AnalyticsProjectsResponse>(URI, request);
  return data;
};

export const listVacanciesRequests = async (request: PaginationQuery) => {
  const URI = '/api/dashboard/offer';
  const { data } = await ApiClient.post<AnalyticsVananciesResponse>(URI, request);
  return data;
};

export const getProjectReleases = async () => {
  const data = releases;
  return data;
};

export const convertDate = (date: string) => {
  const convertedDate = dayjs(date, 'DD.MM.YYYY HH:mm');
  return convertedDate;
};
