import React from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { Divider } from '@mui/material';
import avatar from '@assets/img/avatar.svg';
import { Dayjs } from 'dayjs';
import { Star } from '@components/Star/Star';
import {
  useGetMessagePageMutation,
  useSetFavoriteMessageMutation,
} from '@store/messages/messages.service';
import { Button } from '@components/MUI/Button';
import styles from './FavoriteMessageItem.module.scss';
import { SetFavoriteCounts } from '@layouts/favorite/FavoriteLayout';

type FavoriteMessageItemProps = {
  id: string;
  authorAvatar?: string;
  authorName: string;
  autorId: string;
  messageText: string;
  sendDate: Dayjs;
  refetch: () => void;
  isLastMessage: boolean;
  totalMessages: number;
  chatId: string;
};

export const FavoriteMessageItem: React.FC<FavoriteMessageItemProps> = ({
  authorAvatar,
  authorName,
  autorId,
  messageText,
  sendDate,
  id,
  refetch,
  isLastMessage,
  totalMessages,
  chatId,
}) => {
  const [context] = useOutletContext<SetFavoriteCounts[]>();
  const [setFavoriteMessage] = useSetFavoriteMessageMutation();
  const navigate = useNavigate();
  const [getMessagePage] = useGetMessagePageMutation();

  const handleGoToMessage = () => {
    getMessagePage({ chatId: chatId, messageId: id, limit: 10 }).then((res) => {
      if ('data' in res) {
        navigate(`/messages/${chatId}/page/${res.data.pageNumber}/messageId/${id}`);
      }
    });
  };

  return (
    <>
      <li className={styles.favoriteMessage}>
        <div className={styles.author}>
          <img
            src={authorAvatar ? authorAvatar : avatar}
            className={styles.authorAvatar}
            alt="avatar"
          />
          <Link to={`/userPage/${autorId}`} className={styles.authorName}>
            {authorName}
          </Link>
        </div>
        <span className={styles.messageText}>{messageText}</span>
        <span className={styles.sendDate}>{sendDate.format('DD.MM.YYYY HH:mm')}</span>
        <Star
          checked={true}
          onChange={() =>
            setFavoriteMessage({ id: id, requestBody: { status: false } })
              .then(() => refetch())
              .then(() => context.setMessagesCount(totalMessages - 1))
          }
        />
        <Button variant="outlined" className={styles.button} onClick={handleGoToMessage}>
          Перейти к сообщению
        </Button>
      </li>
      {!isLastMessage ? (
        <Divider style={{ borderColor: '#78858b', marginRight: '1.5rem' }} />
      ) : (
        <></>
      )}
    </>
  );
};
