import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { closeModal } from '@store/ui/ui.slice';
import { Form, FormikBag, FormikContextType, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { SelectData, fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { useAppDispatch } from '@hooks/index';
import * as TeamService from '@store/team/team.service';
import styles from './CreateVacancyModal.module.scss';
import { Select } from '@components/MUI/Select';
import { InputField } from '@components/MUI/InputField/InputField';
import { FormWrapper } from '@components/Form/Form';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Button } from '@components/MUI/Button';
import { useDictionary } from '@hooks/useDictionary';
import { DictionaryName } from '@store/dictionary/contracts';
import { useLocation } from 'react-router-dom';
import { useLazySearchProfilesQuery } from '@store/user/user.slice';

export type EditVacancyValue = {
  id: string;
  name: string;
  teamId: string;
  teamPositionId: string;
  directionId: string;
  stack: string;
  comment: string;
  payment: number;
  isOpen: boolean;
};

export type EditVacancyProps = {
  value: EditVacancyValue;
  submit: (value: EditVacancyValue, form: FormikBag<EditVacancyProps, EditVacancyValue>) => void;
};

const editVacancySchema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  teamId: Yup.string().required('Поле обязательно для заполнения'),
  teamPositionId: Yup.string().required('Поле обязательно для заполнения'),
  directionId: Yup.string().required('Поле обязательно для заполнения'),
  stack: Yup.string().required('Поле обязательно для заполнения'),
  payment: Yup.string()
    .required('Поле обязательно для заполнения')
    .matches(
      /^(100000000(\.0{1,2})?|([0-9]{1,8})(\.[0-9]{1,2})?)$/,
      'Введите число не меньше 0, не более 100000000 и не более 2 знаков после запятой',
    ),
  comment: Yup.string(),
});

function useVacancyFormOptions(form: FormikContextType<EditVacancyValue>): {
  teams: SelectData;
  roles: SelectData;
  executors: SelectData;
} {
  const [teamList, setTeamList] = useState<SelectData>({
    options: [],
    name: 'teamId',
    placeholder: 'Команда',
  });

  const [teamPosition, setTeamPosition] = useState<SelectData>({
    options: [],
    name: 'teamPositionId',
    placeholder: 'Роль',
  });

  const [executors, setExecutors] = useState<SelectData>({
    options: [],
    name: 'executorId',
    placeholder: 'Исполнитель',
  });

  const executorId = useDictionary(DictionaryName.ROLE, fromDictionaryToSelectOption).find(
    (role) => role.label === 'Исполнитель',
  )?.value;

  const [searchProfiles] = useLazySearchProfilesQuery();

  useEffect(() => {
    TeamService.getTeamList({ start: 0, limit: 50, needFreePositions: false })
      .then((resp) =>
        setTeamList((state) => {
          return {
            ...state,
            options: resp.data.map((team) => ({
              value: team.id,
              label: team.name,
            })),
          };
        }),
      )
      .catch((e) => {
        // console.error(e)
      });
  }, []);

  useEffect(() => {
    if (!form.values.teamId) {
      setTeamPosition((state) => {
        return {
          ...state,
          disabled: true,
        };
      });
      return;
    }
    TeamService.getAvailablePositions({ id: form.values.teamId })
      .then((response) => {
        setTeamPosition((state) => {
          if (form.values.teamPositionId) {
            const teamPosition = response.find(
              (position) => position.positionId === form.values.teamPositionId,
            );
            form.setFieldValue('teamPositionId', teamPosition?.id);
          }
          return {
            ...state,
            options: response.map((position) => ({
              value: position.id,
              label: position.positionName,
            })),
            disabled: response.length === 0,
          };
        });
      })
      .catch((error) => {});
  }, [form.values.teamId]);

  useEffect(() => {
    searchProfiles({ query: '', roleId: executorId })
      .then((response) => {
        if (response?.data?.data) {
          setExecutors((state) => {
            return {
              ...state,
              options: response!.data!.data?.map((executor) => ({
                value: executor.userId,
                label: executor.userName ? executor.userName : '',
              })),
              disabled: response!.data?.data?.length === 0,
            };
          });
        }
      })
      .catch((e) => {
        // console.error(e)
      });
  }, []);

  return {
    teams: teamList,
    roles: teamPosition,
    executors: executors,
  };
}

export const EditVacancyModal: React.FC = () => {
  const directions = useDictionary(DictionaryName.DIRECTION, fromDictionaryToSelectOption);
  const editVacancyModalForm = useFormikContext<EditVacancyValue>();
  const formOptions = useVacancyFormOptions(editVacancyModalForm);
  const dispatch = useAppDispatch();
  const route = useLocation();
  const [open, setOpen] = useState(true);

  editVacancyModalForm.validationSchema = editVacancySchema;

  const disableFields = useMemo(() => {
    if (route.pathname === `/teams/${editVacancyModalForm.values.teamId}/members`) return true;
    else return false;
  }, []);

  const cancel = useCallback(() => {
    dispatch(closeModal());
    setOpen(false);
  }, [dispatch]);

  return (
    <>
      <Modal
        open={open}
        onClose={cancel}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ maxWidth: '480px', width: '100%', maxHeight: '750px', overflow: 'auto' }}>
          <FormWrapper label={'Редактирование вакансии'}>
            <Form onSubmit={editVacancyModalForm.handleSubmit}>
              <InputField
                name="name"
                placeholder="Название вакансии"
                type="text"
                className={styles.name__field}
                style={{ marginTop: '1.5rem' }}
              />
              <Select
                values={formOptions.teams.options}
                name={formOptions.teams.name}
                label={formOptions.teams.placeholder}
                isOutlined={true}
                style={{ marginTop: '1.5rem' }}
                value={editVacancyModalForm.values.teamId}
                disabled={formOptions.teams.disabled || disableFields}
              />
              <Select
                values={formOptions.roles.options}
                name={formOptions.roles.name}
                label={formOptions.roles.placeholder}
                style={{ marginTop: '1.5rem' }}
                isOutlined={true}
                value={editVacancyModalForm.values.teamPositionId}
                disabled={formOptions.roles.disabled || disableFields}
              />
              <Select
                name="directionId"
                values={directions}
                label={'Направления'}
                style={{ marginTop: '1.5rem' }}
                isOutlined={true}
                value={editVacancyModalForm.values.directionId}
              />
              <InputField
                style={{ marginTop: '1.5rem' }}
                type="text"
                placeholder="Стек"
                name="stack"
                className={styles.stack__field}
              />
              <InputField
                style={{ marginTop: '1.5rem' }}
                placeholder="Ставка"
                className={styles.payment__field}
                name="payment"
                type="number"
              />
              <InputField
                name="comment"
                placeholder="Комментарий"
                multiline={true}
                rows={4}
                type="text"
                className={styles.payment__field}
                style={{ marginTop: '1.5rem' }}
              />
              <FormControlLabel
                control={<Checkbox name="isOpen" />}
                label="Разрешить отклики другим пользователям"
                onChange={editVacancyModalForm.handleChange}
                value={Boolean(editVacancyModalForm.values.isOpen)}
                checked={Boolean(editVacancyModalForm.values.isOpen)}
                className={styles.checkbox}
              />
              <div className="d-flex justify-content-end mt-5">
                <Button
                  className="color-gray border-0 bg-transparent fw-bold me-4"
                  style={{ color: '#78858B', padding: '10px 20px' }}
                  onClick={cancel}>
                  Отменить
                </Button>
                <Button
                  disabled={!editVacancyModalForm.isValid}
                  style={{ padding: '10px 20px' }}
                  variant="contained"
                  type="submit"
                  disableElevation={true}>
                  Сохранить
                </Button>
              </div>
            </Form>
          </FormWrapper>
        </Box>
      </Modal>
    </>
  );
};

export const EditVacancyForm = withFormik<EditVacancyProps, EditVacancyValue>({
  displayName: 'EditVacancyForm',
  mapPropsToValues: (props) => {
    return {
      id: props.value.id,
      name: props.value.name,
      teamId: props.value.teamId,
      teamPositionId: props.value.teamPositionId,
      directionId: props.value.directionId,
      stack: props.value.stack,
      comment: props.value.comment,
      payment: props.value.payment,
      isOpen: props.value.isOpen,
    };
  },
  isInitialValid: false,
  enableReinitialize: true,
  validationSchema: editVacancySchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(EditVacancyModal);
