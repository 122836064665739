import React from 'react';
import { FormWrapper } from '@components/Form/Form';
import { Button } from '@components/MUI/Button';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Box, Modal } from '@mui/material';
import { TeamItem } from '@store/team/contracts';
import { useUpdateTeamMutation } from '@store/team/team.slice';
import { closeModal, isModalOpen } from '@store/ui/ui.slice';
import styles from './DeletePositionModal.module.scss';

type DeletePositionModalProps = {
  team?: TeamItem | null;
  positionId: string;
};

export const DeletePositionModal: React.FC<DeletePositionModalProps> = ({ team, positionId }) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isModalOpen);

  const [updateTeamMutation] = useUpdateTeamMutation();

  const handleDeletePosition = async () => {
    const newTeamPositions = team?.positions.filter((position) => position.id !== positionId) || [];
    try {
      await updateTeamMutation({
        id: team?.id || '',
        positions: newTeamPositions.map((position) => ({
          id: position.id,
          positionId: position.positionId,
        })),
      });
    } catch (e: any) {
    } finally {
      dispatch(closeModal());
    }
  };

  return (
    <Modal open={isOpen} onClose={() => dispatch(closeModal())} className={styles.modal}>
      <Box className={styles.container}>
        <FormWrapper label={'Исключить участника из проекта?'} titleClassName={styles.title}>
          <div>Данное действие нельзя будет отменить. Продолжить?</div>
          <div className={styles.button__group}>
            <Button className={styles.button__cancel} onClick={() => dispatch(closeModal())}>
              Отменить
            </Button>
            <Button variant="contained" onClick={handleDeletePosition}>
              Исключить
            </Button>
          </div>
        </FormWrapper>
      </Box>
    </Modal>
  );
};
