import { ApiClient } from '@store/api-client';
import {
  GetManagerProjectsResponse,
  GetProjectsResponse,
  GetTeamAnalyticResponse,
  GetTeamProjectsResponse,
  Project,
  ProjectStatusesResponse,
  ProjectUpdateResponse,
  budgetAnalyticsData,
  commonAnalytics,
} from './contracts';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { enqueueSnackbar } from 'notistack';
import { PathParameters, RequestBody, ResponseBody } from '@store/utility';
import { TrackerExecutor } from '@store/tasks/contracts';

export const getTeamAnalytic = async (
  projectId: PathParameters<Api.operations['getTeamAnalytic'], 'projectId'>,
  requestBody: RequestBody<Api.operations['getTeamAnalytic']>,
) => {
  const { data } = await ApiClient.post<
    Api.operations['getTeamAnalytic']['responses']['200']['content']['application/json']
  >(`/api/project/${projectId}/report/team`, requestBody);
  return plainToInstance(GetTeamAnalyticResponse, data);
};

export const detailProject = async (id: PathParameters<Api.operations['getProject'], 'id'>) => {
  const { data } = await ApiClient.get<ResponseBody<Api.operations['getProject']>>(
    `/api/project/${id}`,
  );
  return plainToInstance(Project, data);
};

//Todo: Удалить если не пользуемся
export const getTeamProjects = async (
  teamId: Api.operations['getTeamProjects']['parameters']['path']['teamId'],
  query?: Api.operations['getTeamProjects']['parameters']['query'],
) => {
  const { data } = await ApiClient.post(`/api/project/team/${teamId}`, query);
  return plainToInstance(GetTeamProjectsResponse, data);
};

export const getCustomerProjects = async (
  requestBody: RequestBody<Api.operations['getOrderProjects']>,
) => {
  const { data } = await ApiClient.post<ResponseBody<Api.operations['getOrderProjects']>>(
    '/api/project/order/list',
    requestBody,
  );
  return plainToInstance(GetProjectsResponse, data);
};

export const getManagerProjects = async (
  request: RequestBody<Api.operations['getManagedProjects']>,
) => {
  const { data } = await ApiClient.post<ResponseBody<Api.operations['getManagedProjects']>>(
    '/api/project/managed/list',
    request,
  );
  return plainToInstance(GetManagerProjectsResponse, data);
};

export const getManagerCustomerProjects = async (
  request: RequestBody<Api.operations['getManagerCustomerProjects']>,
) => {
  const { data } = await ApiClient.post<ResponseBody<Api.operations['getManagerCustomerProjects']>>(
    '/api/project/manager/customer/list',
    request,
  );
  return plainToInstance(GetProjectsResponse, data);
};

export const getProjects = async (request: RequestBody<Api.operations['getUserProjects']>) => {
  const { data } = await ApiClient.post<ResponseBody<Api.operations['getUserProjects']>>(
    '/api/project/list',
    request,
  );
  return plainToInstance(GetProjectsResponse, data);
};

export const getExecutorProjects = async (
  requestBody: RequestBody<Api.operations['getUserProjects']>,
) => {
  const { data } = await ApiClient.post<ResponseBody<Api.operations['getUserProjects']>>(
    '/api/project/executor/list',
    requestBody,
  );
  return plainToInstance(GetProjectsResponse, data);
};

export const updateProject = async (request: RequestBody<Api.operations['saveProject']>) => {
  const { data } = await ApiClient.post<ProjectUpdateResponse>('api/project', request);
  enqueueSnackbar(`Проект отредактирован`, {
    variant: 'success',
  });
  return data;
};

export const getProjectStatuses = async (
  projectId: PathParameters<Api.operations['getAvailableStatuses'], 'projectId'>,
) => {
  const { data } = await ApiClient.get<ResponseBody<Api.operations['getAvailableStatuses']>>(
    `/api/project/${projectId}/statuses`,
  );
  return plainToInstance(ProjectStatusesResponse, data);
};

export const connectYandexTrackerToProject = async (
  projectId: string,
  payload: {
    organizationId: string;
    externalProjectId: string;
  },
) => {
  await ApiClient.post(`/api/project/${projectId}/tracker/yandex/connect`, payload);
  enqueueSnackbar(`Проект успешно подключен к Яндекс.Трекер`, {
    variant: 'success',
  });
};

export const disconnectYandexTrackerToProject = async (projectId: string) => {
  await ApiClient.post(`/api/project/${projectId}/tracker/yandex/disconnect`);
  enqueueSnackbar(`Проект успешно отключен от Яндекс.Трекер`, {
    variant: 'success',
  });
};

export const getProjectQueues = async (projectId: string) => {
  const { data } = await ApiClient.get<any[]>(`/api/tracker/project/${projectId}/queues`);
  return data;
};

export const getProjectTypes = async (projectId: string, queueId: string) => {
  const { data } = await ApiClient.get<any[]>(
    `/api/tracker/project/${projectId}/queues/${queueId}/task-types`,
  );
  return data;
};

export const getProjectPriorities = async (projectId: string) => {
  const { data } = await ApiClient.get<any[]>(`/api/tracker/project/${projectId}/priorities`);
  return data;
};

export const getProjectExecutors = async (projectId: string) => {
  const { data } = await ApiClient.get<TrackerExecutor[]>(
    `/api/tracker/project/${projectId}/executors`,
  );
  return plainToInstance(TrackerExecutor, data);
};

export const getCommonAnalytics = async () => {
  const data = commonAnalytics;
  return data;
};

export const getBudgetAnalytics = async () => {
  const data = budgetAnalyticsData;
  return data;
};
