import React, { FC } from 'react';
import {
  ProfileFormWorkProps,
  ProfileWorkForm,
} from '@pages/profile/profileEditForms/ProfileWorkForm';
import {
  ProfileContactForm,
  ProfileFormContactProps,
} from '@pages/profile/profileEditForms/ProfileContactForm';
import { useAppSelector } from '@hooks/index';
import {
  selectProfile,
  useMeQuery,
  useSetInfoAboutCompanyMutation,
  useSetInfoAboutLinkMutation,
  useSetInfoAboutWorkMutation,
  useSetProfileMutation,
} from '@store/user/user.slice';
import {
  ProfileAboutMeForm,
  ProfileFormAboutMeProps,
} from '@pages/profile/profileEditForms/ProfileAboutMeForm';
import {
  ProfileAboutCompanyForm,
  ProfileFormAboutCompanyProps,
} from '@pages/profile/profileEditForms/ProfileAboutCompanyForm';
import { ProfilePasswordForm } from './profileEditForms/ProfilePasswordForm';
import {
  ProfileRoleSelectForm,
  ProfileRoleSelectProps,
} from './profileEditForms/ProfileRoleSelectForm';
import {
  ProfileFormSpecialityProps,
  ProfileSpecialityForm,
} from './profileEditForms/ProfileSpecialityForm';
import styles from './ProfileEdit.module.scss';
import { useGetMyRoles } from '@hooks/useGetMyRoles';
import { FileItem } from '@store/user/contracts';
import { useScrollToTop } from '@hooks/useScrollToTop';

const ProfileEdit: FC = () => {
  const user = useAppSelector(selectProfile)?.profile;
  const { formattedRoles, getFormattedRoles, myRoles } = useGetMyRoles();
  const [setAboutMe] = useSetProfileMutation();
  const [setInfoAboutWork] = useSetInfoAboutWorkMutation();
  const [setInfoAboutCompany] = useSetInfoAboutCompanyMutation();
  const [setInfoAboutLink] = useSetInfoAboutLinkMutation();
  const topRef = useScrollToTop();
  useMeQuery({});

  const getIconUuid = (urlString?: string): string | undefined => {
    if (!urlString) return '';
    try {
      const url = new URL(urlString);
      // Split into URL segments and remove any empty segments
      const pathSegments = url.pathname.split('/').filter(Boolean);

      return pathSegments.length > 0 ? pathSegments.pop() : '';
    } catch (err) {
      return '';
    }
  };

  const submitFormAboutMe: ProfileFormAboutMeProps['submit'] = async (
    ProfileSpecialityForm,
    form,
  ) => {
    try {
      await setAboutMe({
        icon: getIconUuid(ProfileSpecialityForm.icon),
        firstName: ProfileSpecialityForm.firstName,
        lastName: ProfileSpecialityForm.lastName,
        patronymic: ProfileSpecialityForm.patronymic,
        userName: ProfileSpecialityForm.userName,
        gender: ProfileSpecialityForm.gender,
        birthDate: ProfileSpecialityForm.birthDate,
        location: ProfileSpecialityForm.location,
        about: ProfileSpecialityForm.about,
        roles: formattedRoles,
      });
    } catch (e: any) {
      form.setErrors(e);
    }
  };

  const submitSpecialityForm: ProfileFormSpecialityProps['submit'] = async (
    ProfileAboutMeForm,
    form,
  ) => {
    try {
      let certificatesIds = ProfileAboutMeForm.certificates?.map(
        (certificate: FileItem) => certificate.id,
      );
      let resumeIds = ProfileAboutMeForm.resume?.map((resume: FileItem) => resume.id);
      await setAboutMe({
        directionId: ProfileAboutMeForm.directionId,
        stack: ProfileAboutMeForm.stack?.split(',') || undefined,
        experience: ProfileAboutMeForm.experience,
        experienceDescription: ProfileAboutMeForm.experienceDescription,
        resumeUrl: ProfileAboutMeForm.resumeUrl,
        resume: resumeIds,
        certificates: certificatesIds,
        roles: formattedRoles,
      });
    } catch (e: any) {
      form.setErrors(e);
    }
  };

  const submitWorkForm: ProfileFormWorkProps['submit'] = async (ProfileWorkForm, form) => {
    try {
      await setInfoAboutWork(ProfileWorkForm);
    } catch (e: any) {
      form.setErrors(e);
    }
  };

  const submitContactForm: ProfileFormContactProps['submit'] = async (
    ProfileContractForm,
    form,
  ) => {
    try {
      setInfoAboutLink({
        contract: ProfileContractForm?.contract || false,
        phones: ProfileContractForm.phones?.split(',') || [],
        telegram: ProfileContractForm?.telegram,
        email: ProfileContractForm?.email,
        urls: ProfileContractForm.urls?.split(','),
      });
    } catch (e: any) {
      form.setErrors(e);
    }
  };

  const submitFormAboutCompany: ProfileFormAboutCompanyProps['submit'] = async (
    ProfileAboutCompanyForm,
    form,
  ) => {
    try {
      await setInfoAboutCompany({
        logo: ProfileAboutCompanyForm.logo,
        name: ProfileAboutCompanyForm.companyName,
        url: ProfileAboutCompanyForm.url,
        phone: ProfileAboutCompanyForm.phone,
        email: ProfileAboutCompanyForm.email,
        inn: ProfileAboutCompanyForm.inn,
        officeLocation: ProfileAboutCompanyForm.officeLocation,
        description: ProfileAboutCompanyForm.description,
        rules: ProfileAboutCompanyForm.rules,
      });
    } catch (e: any) {
      form.setErrors({
        companyName: e.message.companyName,
      });
    }
  };

  const submitFormRole: ProfileRoleSelectProps['submit'] = async (ProfileRoleSelect, form) => {
    try {
      const updatedRoles = getFormattedRoles(ProfileRoleSelect.roles);
      await setAboutMe({
        roles: updatedRoles,
      });
    } catch (e: any) {
      form.setErrors(e);
    }
  };
  return (
    <div className={styles.edit__forms__wrapper} ref={topRef}>
      <ProfileRoleSelectForm
        value={{
          roles: myRoles || [],
        }}
        submit={submitFormRole}
      />
      <ProfileAboutMeForm
        value={{
          firstName: user?.about?.firstName || undefined,
          lastName: user?.about?.lastName || undefined,
          patronymic: user?.about?.patronymic || undefined,
          userName: user?.about?.userName || undefined,
          gender: user?.about?.gender || undefined,
          birthDate: user?.about?.birthDate
            ? user?.about?.birthDate?.format('YYYY-MM-DD')
            : undefined,
          location: user?.about?.location || undefined,
          about: user?.about?.about || undefined,
          icon: user?.about?.icon || undefined,
          roles: user?.about?.roles || [],
        }}
        submit={submitFormAboutMe}
      />
      {formattedRoles.includes('executor') && (
        <ProfileSpecialityForm
          value={{
            directionId: user?.about?.directionId || undefined,
            stack: user?.about?.stack?.join(',') || undefined,
            experience: user?.about?.experience || undefined,
            experienceDescription: user?.about?.experienceDescription || undefined,
            resumeUrl: user?.about?.resumeUrl || undefined,
            resume: user?.about?.resume || undefined,
            certificates: user?.about?.certificates || undefined,
            roles: user?.about?.roles || [],
          }}
          submit={submitSpecialityForm}
        />
      )}
      {(formattedRoles.includes('manager') || formattedRoles.includes('executor')) && (
        <ProfileWorkForm
          value={{
            workStatusId: user?.work?.workStatusId || '',
            hourPrice: user?.work?.hourPrice || 0,
            monthPrice: user?.work?.monthPrice || undefined,
            currency: undefined,
            manager: user?.work?.manager || undefined,
          }}
          submit={submitWorkForm}
        />
      )}
      <ProfileContactForm
        value={{
          telegram: user?.link?.telegram || undefined,
          phones: user?.link?.phones?.join(',') || undefined,
          email: user?.link?.email || undefined,
          contract: user?.link?.contract || false,
        }}
        submit={submitContactForm}
      />
      {formattedRoles.includes('customer') && (
        <ProfileAboutCompanyForm
          value={{
            logo: user?.organization?.logo || undefined,
            companyName: user?.organization?.name || undefined,
            url: user?.organization?.url || undefined,
            phone: user?.organization?.phone || undefined,
            email: user?.organization?.email || undefined,
            inn: user?.organization?.inn || undefined,
            officeLocation: user?.organization?.officeLocation || undefined,
            description: user?.organization?.description || undefined,
            rules: user?.organization?.rules || undefined,
          }}
          submit={submitFormAboutCompany}
        />
      )}
      <ProfilePasswordForm />
    </div>
  );
};

export { ProfileEdit };
