import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { getDocsFavorite } from '@store/docs/docs.service';
import { getFavoriteRequests } from '@store/requests/requests.service';
import { getTasksFavorite } from '@store/tasks/tracker.service';
import { getFavoriteTeams } from '@store/team/team.service';
import { getFavoriteProfiles } from '@store/user/user.service';
import { getFavoriteVacancies } from '@store/vacancy/vacancy.service';

type FavoriteState = {
  usersCount: number | null;
  teamsCount: number | null;
  tasksCount: number | null;
  documentsCount: number | null;
  requestsCount: number | null;
  vacanciesCount: number | null;
};

const initialState: FavoriteState = {
  usersCount: null,
  teamsCount: null,
  tasksCount: null,
  documentsCount: null,
  requestsCount: null,
  vacanciesCount: null,
};

export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    setUsersCount: (state, action: PayloadAction<number>) => {
      state.usersCount = action.payload;
    },
    setTeamsCount: (state, action: PayloadAction<number>) => {
      state.teamsCount = action.payload;
    },
    setTasksCount: (state, action: PayloadAction<number>) => {
      state.tasksCount = action.payload;
    },
    setDocumentsCount: (state, action: PayloadAction<number>) => {
      state.documentsCount = action.payload;
    },
    setRequestsCount: (state, action: PayloadAction<number>) => {
      state.requestsCount = action.payload;
    },
    setVacanciesCount: (state, action: PayloadAction<number>) => {
      state.vacanciesCount = action.payload;
    },
  },
});

export const getFavoriteCounts = createAsyncThunk(
  'favorite/getFavoriteCounts',
  async (_, { dispatch }) => {
    Promise.allSettled([
      getFavoriteProfiles({}),
      getFavoriteTeams({}),
      getTasksFavorite({}),
      getDocsFavorite({}),
      getFavoriteRequests({}),
      getFavoriteVacancies({}),
    ]).then((res) => {
      if (res[0].status === 'fulfilled') dispatch(setUsersCount(res[0].value.totalCount));
      if (res[1].status === 'fulfilled') dispatch(setTeamsCount(res[1].value.totalCount));
      if (res[2].status === 'fulfilled') dispatch(setTasksCount(res[2].value.totalCount));
      if (res[3].status === 'fulfilled') dispatch(setDocumentsCount(res[3].value.totalCount));
      if (res[4].status === 'fulfilled') dispatch(setRequestsCount(res[4].value.totalCount));
      if (res[5].status === 'fulfilled') dispatch(setVacanciesCount(res[5].value.totalCount));
    });
  },
);

export const {
  setUsersCount,
  setTeamsCount,
  setTasksCount,
  setDocumentsCount,
  setRequestsCount,
  setVacanciesCount,
} = favoriteSlice.actions;

export const selectUsersCount = (state: RootState) => state.favorite.usersCount;
export const selectTeamsCount = (state: RootState) => state.favorite.teamsCount;
export const selectTasksCount = (state: RootState) => state.favorite.tasksCount;
export const selectDocumentsCount = (state: RootState) => state.favorite.documentsCount;
export const selectRequestsCount = (state: RootState) => state.favorite.requestsCount;
export const selectVacanciesCount = (state: RootState) => state.favorite.vacanciesCount;

export const favoriteReducer = favoriteSlice.reducer;
