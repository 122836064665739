import { Alert, Box, styled, Tooltip, Typography as MTypography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import styles from './Rates.module.scss';
import tilda from '@assets/img/aboutInfo/tilda.svg';
import { TabsWrappedLabel } from '@components/MUI/TabsWrappedLabel';
import { RatesPageForCustomer } from '@pages/rates/ratesCard/RatesPageForCustomer';
import { CardOptionsRates } from '@pages/rates/additionalOptionsCard/CardOptionsRates';
import { useMemo } from 'react';
import { PrivateProfileRatesForExecutor } from '@pages/rates/privateProfileRates/PrivateProfileRatesForExecutor';
import { useAppSelector } from '@hooks/index';
import { selectProfile } from '@store/user/user.slice';
import { Navigate } from 'react-router-dom';
import { useScrollToTop } from '@hooks/useScrollToTop';

const alertStyles = {
  color: '#26282C',
  marginBottom: '2rem',
};

export const Typography = styled(MTypography)`
  color: #39393a;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  margin-bottom: 18px;
  align-items: end;

  &:before {
    content: url(${tilda});
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  & sup {
    color: #199b3e;
    top: -1.5rem;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    align-items: start;
    sup {
      top: -0.2rem;
    }
  }
`;

export const Rates = () => {
  const topRef = useScrollToTop();
  const user = useAppSelector(selectProfile);

  const licenseExpiredAt = useMemo(() => {
    return user!.profile?.created.add(6, 'month')!;
  }, [user]);

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="ms-5 me-4" ref={topRef}>
        <Alert severity="error" style={alertStyles} icon={false}>
          Для новых пользователей подписка на тариф «Стартап» первые 6 месяцев бесплатна!
        </Alert>
        <div className="d-flex align-items-center">
          <p className="m-0">
            У вас подключен тариф «Стартап» для роли Исполнитель, он действителен до{' '}
            <span className={styles.textDanger}>{licenseExpiredAt.format('DD.MM.YYYY')}</span>
            <Tooltip arrow title="Тариф Стартап - 6 месяцев бесплатно">
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </p>
        </div>
        <div className="d-flex align-items-center">
          <p className="m-0">
            У вас подключен тариф «Стартап» для роли Заказчик, он действителен до{' '}
            <span className={styles.textDanger}>{licenseExpiredAt.format('DD.MM.YYYY')}</span>
            <Tooltip arrow title="Тариф Стартап - 6 месяцев бесплатно">
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </p>
        </div>
        <Box
          sx={{
            borderRadius: '4px',
            background: '#F5F7FA',
            padding: '24px 32px 14px 32px',
            marginBottom: '3.25rem',
            marginTop: '2.5rem',
          }}>
          <h4 className={styles.title}>
            Для удобства пользования сервисом Egile оформите подписку
          </h4>
          <p className={styles.subTitle}>
            Подписка на Egile — это возможность для Пользователя за абонентскую плату в течение
            определенного промежутка времени получать удаленный доступ к платформе через сеть
            “Интернет".
          </p>
          <Typography>Для новых пользователей подписка на первые полгода бесплатна</Typography>
          <Typography>Для расширения возможностей выберете подходящий для вас тариф</Typography>
        </Box>
        <Box>
          <h2>Тарифы</h2>
          <div className="mt-4">
            <TabsWrappedLabel
              tabs={[
                {
                  label: 'Для исполнителя',
                  content: <PrivateProfileRatesForExecutor />,
                },
                {
                  label: 'Для заказчика',
                  content: <RatesPageForCustomer />,
                },
              ]}
            />
          </div>
        </Box>
        <Box>
          <h2 className={styles.optionTitle}>Дополнительные услуги (в разработке)</h2>
          <div className={`${styles.gridLayout} ${styles.wrapper}`}>
            <CardOptionsRates
              title="Выделение проекта цветом"
              subTitle="Разовая оплата"
              description="Ваш проект или вакансия будет выделена цветом в списке, что поможет стать более заметным"
              rate="Для тарифа «Стартап» "
              allRates="Для тарифа «Персональный» и тарифа «Премиум» "
              price="300 ₽"
              priceForAnotherRates="150 ₽"
            />
            <CardOptionsRates
              title="Срочность проекта"
              subTitle="Разовая оплата"
              description="Какой-то текст, который объясняет, как это работает и почему лучше воспользоваться этой опцией"
              rate="Для тарифа «Стартап» "
              price="250 ₽"
              allRates="Для тарифа «Персональный» и тарифа «Премиум» "
              priceForAnotherRates="125 ₽"
            />
            <CardOptionsRates
              title="Комплексное продвижение проекта"
              subTitle="Разовая оплата"
              description="Все опции по выгодной цене: закрепление в ленте на 24 часа, отметка «срочно», выделение проекта цветом"
              rate="Для тарифа «Стартап» "
              allRates="Для тарифа «Персональный» и тарифа «Премиум» "
              price="300 ₽"
              priceForAnotherRates="150 ₽"
            />
            <CardOptionsRates
              title="Открыть проект для всех"
              subTitle="Разовая оплата"
              description="На ваш проект смогут ответить фрилансеры с любым типом аккаунта  без ограничений"
              rate="Для тарифа «Стартап» "
              allRates="Для тарифа «Персональный» и тарифа «Премиум» "
              price="-"
              priceForAnotherRates="250 ₽"
            />
            <CardOptionsRates
              title="Дополнительные специализации"
              subTitle="Разовая оплата"
              description="Размещайте проект сразу в трех специализациях, чтоб быстрее найти исполнителя"
              rate="Для тарифа «Стартап» "
              allRates="Для тарифа «Персональный» и тарифа «Премиум» "
              price="500 ₽"
              priceForAnotherRates="Включены в тариф"
            />
            <CardOptionsRates
              title="Закрепить проект в ленте на 24 часа"
              subTitle="Разовая оплата"
              description="После закрепления проект останется наверху ленты, и его увидят больше фрилансеров"
              rate="Для тарифа «Стартап» "
              allRates="Для тарифа «Персональный» и тарифа «Премиум» "
              price="100 ₽"
              priceForAnotherRates="50 ₽"
            />
            <CardOptionsRates
              title="Опубликовать проект"
              subTitle="Разовая оплата"
              description="Открывает возможность публикации дополнительного проекта. Разовая оплата"
              rate="Для тарифа «Стартап» "
              allRates="Для тарифа «Персональный» и тарифа «Премиум» "
              price="200 ₽"
              priceForAnotherRates="200 ₽"
            />
          </div>
        </Box>
      </div>
    </>
  );
};
