import React, { useState } from 'react';
import { Modal } from '@components/MUI/Modal/Modal';
import { FormControlLabel, Paper, Radio } from '@mui/material';
import { RequestParams } from '@components/Table/TableHeadCell';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import styles from './SortModal.module.scss';
import { Columns } from '@components/Table/TableMUI';

type SortModalProps = {
  sortColumns: [string, Columns][];
  sortColumn: [string, Columns];
  sortOrder: 'asc' | 'desc' | undefined;
  setSortColumn: React.Dispatch<React.SetStateAction<[string, Columns]>>;
  setSortOrder?: (sortOrder: 'asc' | 'desc' | undefined) => void;
  onFilterChange?: (value: Record<string, RequestParams>) => void;
  setSortColumnHook?: (column: string) => void;
};

export const SortModal: React.FC<SortModalProps> = ({
  sortColumns,
  setSortColumn,
  sortColumn,
  sortOrder,
  setSortOrder,
  onFilterChange,
  setSortColumnHook,
}) => {
  const dispatch = useAppDispatch();
  const [sortColumnModal, setSortColumnModal] = useState(sortColumn);
  const [sortOrderModal, setSortOrderModal] = useState(sortOrder);

  const setAscending = (column: [string, Columns]) => {
    setSortColumn(column);
    setSortColumnModal(column);
    setSortColumnHook?.(column[0]);
    setSortOrder?.('asc');
    setSortOrderModal('asc');
    const requestParam: RequestParams = {
      searchType: 'LIKE',
      sort: true,
      value: undefined,
    };
    onFilterChange?.({ [column[0]]: requestParam });
    dispatch(closeModal());
  };

  const setDescending = (column: [string, Columns]) => {
    setSortColumn(column);
    setSortColumnModal(column);
    setSortOrder?.('desc');
    setSortOrderModal('desc');
    setSortColumnHook?.(column[0]);
    const requestParam: RequestParams = {
      searchType: 'LIKE',
      sort: true,
      value: undefined,
    };
    onFilterChange?.({ [column[0]]: requestParam });
    dispatch(closeModal());
  };

  return (
    <Modal>
      <Paper variant="outlined">
        <div className={styles.sort__modal}>
          {sortColumns.map((column) => (
            <div className={styles.sort__modal__item}>
              <FormControlLabel
                control={<Radio />}
                label={column[1].label + ' (убывание)'}
                onChange={() => setDescending(column)}
                checked={
                  column[1].label === sortColumnModal[1].label && sortOrderModal === 'desc'
                    ? true
                    : false
                }
              />
              <FormControlLabel
                control={<Radio />}
                label={column[1].label + ' (возрастание)'}
                onChange={() => setAscending(column)}
                checked={
                  column[1].label === sortColumnModal[1].label && sortOrderModal === 'asc'
                    ? true
                    : false
                }
              />
            </div>
          ))}
        </div>
      </Paper>
    </Modal>
  );
};
