import React, { FC, useRef, useState } from 'react';
import { Modal, ModalFooter } from '@components/MUI/Modal/Modal';
import {
  AssignTeamForm,
  AssignTeamFormRef,
  AssignTeamFormValue,
} from '@pages/project/components/AssignTeam/AssignTeamForm';
import { useDetailProjectQuery, useUpdateProjectMutation } from '@store/project/project.slice';
import { apiErrorHandler } from '@store/api-client';
import { Button } from '@components/MUI/Button';
import { useGetTeamListQuery } from '@store/team/team.slice';
import styles from '@styles/Projects.module.scss';
import { Divider, InputAdornment, TextField } from '@mui/material';
import { ReactComponent as SearchGray } from '@assets/img/searchGray.svg';
import { debounce } from 'lodash';

interface Props {
  onClose: () => void;
  projectId?: string;
}

const AssignTeamModal: FC<Props> = ({ onClose, projectId }) => {
  const formRef = useRef<AssignTeamFormRef | undefined>();
  const [processing, setProcessing] = useState(false);
  const [filteredValue, setFilteredValue] = useState('');
  const [updateProject] = useUpdateProjectMutation();

  const { data: myTeams } = useGetTeamListQuery({
    limit: 50,
    start: 0,
  });

  const { data: project } = useDetailProjectQuery(projectId || '');

  const handleFilterChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFilteredValue(e.target.value);
    },
    500,
  );

  const handleClick = () => {
    if (formRef.current?.isValid === true) {
      formRef.current.submitForm();
    }
  };

  const handleSubmit = async ({ teamId }: AssignTeamFormValue) => {
    setProcessing(true);
    try {
      if (project) {
        await updateProject({
          id: project.id,
          attachments: project.attachments.map((attachment) => attachment.id),
          budget: project.budget,
          deadLine: project.deadLine.format('DD.MM.YYYY HH:MM'),
          description: project.description,
          name: project.name,
          projectStatusId: project.projectStatusId,
          projectTypeId: project.projectTypeId,
          requirement: project.requirement,
          stack: project.stack,
          task: project.task,
          visibilityStatusId: project.visibilityStatusId,
          teamId,
        });
      }
      onClose();
    } catch (error) {
      apiErrorHandler(error);
    }
    setProcessing(false);
  };

  return (
    <Modal>
      <div className={styles.formWrapper}>
        <div className={styles.headTeam}>
          <h2 className={styles.teamLabel}>Команды</h2>
          <div>
            <TextField
              size="small"
              placeholder="Поиск"
              onChange={handleFilterChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchGray />
                  </InputAdornment>
                ),
              }}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#199b3e',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#199b3e',
                  },
                },
              }}
            />
          </div>
        </div>
        <Divider className={styles.divider} />
        <div>
          <AssignTeamForm
            formRef={formRef}
            submit={handleSubmit}
            teams={myTeams?.data?.filter((team) => team?.name?.includes(filteredValue)) || []}
          />
        </div>
        <Divider className={styles.divider} />
        <ModalFooter>
          <Button className={styles.cancelAddTeamButton} onClick={onClose} size="large">
            Отменить
          </Button>
          <Button
            disabled={processing}
            onClick={handleClick}
            variant="contained"
            className={styles.submitAddTeamButton}
            size="large">
            Назначить
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export { AssignTeamModal };
