import { FormWrapper } from '@components/Form/Form';
import { Box, Divider, Modal } from '@mui/material';
import React, { useCallback, useState } from 'react';
import avatar from '@assets/img/avatar.svg';
import { useAppDispatch } from '@hooks/index';
import { closeModal, openModalError } from '@store/ui/ui.slice';
import { CreateOfferForm, CreateOfferFormProps } from './CreateOfferForm';
import styles from './CreateOffer.module.scss';
import { useCreateOfferMutation } from '@store/offer/offer.slice';

type CreateOfferProps = {
  executorName?: string;
  executorDirection?: string;
  executorStack?: string;
  executorPayment?: number | null;
  executorId: string;
};

export const CreateOfferModal: React.FC<CreateOfferProps> = ({
  executorName,
  executorDirection,
  executorStack,
  executorPayment,
  executorId,
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(true);
  const [createOffer] = useCreateOfferMutation();

  const handleSubmit: CreateOfferFormProps['submit'] = async (formValues) => {
    try {
      createOffer({
        fromManager: true,
        receiverId: executorId,
        vacancyId: formValues.vacancyId,
      }).then(() => {
        setOpen(false);
        dispatch(closeModal());
      });
    } catch (e: any) {
      dispatch(openModalError(e));
    }
  };

  const cancel = useCallback(() => {
    dispatch(closeModal());
    setOpen(false);
  }, [dispatch]);

  return (
    <Modal
      open={open}
      onClose={cancel}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box style={{ maxWidth: '480px', width: '100%' }}>
        <FormWrapper label={'Отправка предложения'}>
          <div className={styles.executor}>
            <img src={avatar} alt="avatar" className={styles.executor__avatar} />
            <div className={styles.executor__name}>{executorName}</div>
          </div>
          <div className={styles.direction}>
            <div className={styles.direction__label}>Направление</div>
            <div className={styles.direction__value}>{executorDirection}</div>
          </div>
          <div className={styles.stack}>
            <div className={styles.stack__label}>Стек</div>
            <div className={styles.stack__value}>{executorStack}</div>
          </div>
          <div className={styles.payment}>
            <div className={styles.payment__label}>Стоимость часа работы</div>
            <div className={styles.payment__value}>
              {executorPayment ? `${executorPayment} ₽` : ''}
            </div>
          </div>

          <Divider style={{ marginBottom: '1.125rem' }} />
          <h5 className={styles.offer__title}>Предложение</h5>

          <CreateOfferForm cancel={cancel} submit={handleSubmit} />
        </FormWrapper>
      </Box>
    </Modal>
  );
};
