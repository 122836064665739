import React, { useEffect, useMemo, useState } from 'react';
import { AsideForm } from '@components/teams/Aside/Aside';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { ProfilesRequestBody } from '@store/user/contracts';
import { Dayjs } from 'dayjs';
import { useDictionary } from '@hooks/useDictionary';
import { DictionaryName } from '@store/dictionary/contracts';
import { fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { Button } from '@components/MUI/Button';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { openModal } from '@store/ui/ui.slice';
import { CreateOfferModal } from './modals/CreateOfferModal';
import { Star } from '@components/Star/Star';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { Link, useOutletContext } from 'react-router-dom';
import { VacanciesLayoutOutletContext } from '@layouts/vacancies/VacanciesLayout';
import { useGetProfilesQuery, useSetFavoriteProfileMutation } from '@store/user/user.slice';

type ExecutorsTableDataType = {
  id: string;
  name: string | undefined;
  role: string | undefined;
  stack: string | undefined | null;
  registration: Dayjs;
  payment: number | null | undefined;
  offer: string;
  isFavorite: boolean;
  status: JSX.Element;
};

const TeamsList: React.FC = () => {
  const outletContext = useOutletContext<VacanciesLayoutOutletContext>();
  useEffect(() => {
    outletContext.setRightSideButton(undefined);
  }, []);
  const dispatch = useAppDispatch();
  const directions = useDictionary(DictionaryName.DIRECTION, fromDictionaryToSelectOption);
  const roles = useAppSelector(selectDictionary(DictionaryName.ROLE));
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [requestBody, setRequestBody] = useState<ProfilesRequestBody>({
    roleId: roles.find((role) => role.code === 'EXECUTOR')!.id,
  });
  const [setFavoriteProfile] = useSetFavoriteProfileMutation();

  const { data: executors } = useGetProfilesQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: 'asc',
    ...requestBody,
  });

  const executorsTableData = useMemo(() => {
    if (executors?.data) {
      const executorsTableData: ExecutorsTableDataType[] = executors?.data?.map((executor) => {
        const executorName =
          executor?.about?.firstName && executor?.about?.lastName
            ? `${executor?.about?.firstName} ${executor?.about?.lastName}`
            : '';
        const direction = directions.find(
          (direction) => direction.value === executor?.about?.directionId,
        )?.label;
        return {
          id: executor.id,
          name: executorName,
          role: direction,
          stack: executor?.about?.stack?.join(', ') || '',
          registration: executor.created,
          payment: executor.work?.hourPrice,
          offer: '',
          isFavorite: executor.favorite,
          status: (
            <Button
              variant="contained"
              disableElevation={true}
              style={{ padding: '4px 10px', marginRight: '1rem' }}
              onClick={() =>
                dispatch(
                  openModal(
                    <CreateOfferModal
                      executorName={executorName}
                      executorDirection={direction}
                      executorStack={executor?.about?.stack?.join(', ')}
                      executorPayment={executor?.work?.hourPrice}
                      executorId={executor?.id}
                    />,
                  ),
                )
              }>
              Предложить
            </Button>
          ),
        };
      });
      return executorsTableData;
    }
    return [];
  }, [executors]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className="col grid-container">
      <div className="text-nowrap">
        <AdaptiveTableMUI
          setRequestBody={setRequestBody}
          isEmpty={executors?.data?.length === 0}
          emptyLabel="Нет специалистов"
          columns={{
            name: {
              label: 'Специалист',
              filterInput: true,
              children: (value, row) => <Link to={`/userPage/${row.id}`}>{value}</Link>,
            },
            role: {
              label: 'Направление',
              filterInput: true,
            },
            stack: {
              label: 'Стэк',
              filterInput: true,
            },
            registration: {
              label: 'Регистрация',
              filterInput: true,
              format: (value: Dayjs) => value.format('DD.MM.YYYY'),
              isSortColumn: true,
            },
            payment: {
              label: 'Ставка',
              filterInput: true,
              format: (value) => (value !== undefined && value !== null ? `${value} р/ч` : ''),
              isSortColumn: true,
            },
            offer: {
              label: 'Предложение',
              filterInput: true,
            },
            status: {},
            star: {
              children: (_, row: ExecutorsTableDataType) => (
                <Star
                  checked={row.isFavorite}
                  onChange={(value) =>
                    setFavoriteProfile({ isFavorite: { status: value }, profileId: row.id })
                  }
                  style={{ marginRight: '1rem' }}
                />
              ),
            },
          }}
          rows={executorsTableData}>
          <TablePaginationMUI
            limit={limit}
            count={executors?.totalCount}
            entityLabel="специалистов"
            page={page}
            handleChangePage={(_, newPage) => setPage(newPage)}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </AdaptiveTableMUI>
      </div>
      <div className="aside-container">
        <AsideForm
          value={{
            location: undefined,
            experienceFrom: undefined,
            experienceTo: undefined,
            hourPriceFrom: undefined,
            hourPriceTo: undefined,
            directionIds: [],
            stack: undefined,
            roleId: roles.find((role) => role.code === 'EXECUTOR')!.id,
          }}
          submit={(values) => setRequestBody(values)}
        />
      </div>
    </div>
  );
};

export { TeamsList };
