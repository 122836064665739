export enum DictionaryName {
  CURRENCY = 'CURRENCY',
  PROJECT_STATUS = 'PROJECT_STATUS',
  DIRECTION = 'DIRECTION',
  OFFER_STATUS = 'OFFER_STATUS',
  POSITION = 'POSITION',
  PROJECT_TYPE = 'PROJECT_TYPE',
  REQUEST_TYPE = 'REQUEST_TYPE',
  TEAM_TYPE = 'TEAM_TYPE',
  WORK_STATUS = 'WORK_STATUS',
  TRACKER = 'TRACKER',
  VACANCY_STATUS = 'VACANCY_STATUS',
  ROLE = 'ROLE',
  CLOUD_BUDGET_LIMIT_ACTION = 'CLOUD_BUDGET_LIMIT_ACTION',
  TASK_STATUS = 'TASK_STATUS',
}

export interface DictionaryValue {
  id: string;
  name: string;
  code: string;
  sortOrder: number;
  nameLocalized: string;
}

export enum STATUS_REQUEST {
  IN_SEARCH = 'Идет поиск',
  PENDING = 'На рассмотрении',
  ACCEPTED = 'Принято',
  ADDED_TO_PROJECT = 'Переведено в проект',
  REJECTED = 'Отклонено',
  DRAFT = 'Черновик',
}
