import React, { useMemo, useState } from 'react';
import { Star } from '@components/Star/Star';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { CreateVacancyOffer } from '@components/teams/CreateVacancyOffer/CreateVacancyOffer';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { useRequestBody } from '@hooks/useRequestBody';
import { Vacancy } from '@store/vacancy/contracts';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { setVacanciesCount } from '@store/favorite/favorite.slice';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import {
  useGetFavoriteVacanciesQuery,
  useSetFavoriteVacancyMutation,
} from '@store/vacancy/vacancy.slice';

type TableVacancies = {
  name: string;
  positionName: string;
  stack: string;
  payment: number;
  comment: string;
  statusName: string;
  star: {};
};

export const FavoriteVacancies: React.FC = () => {
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();
  const [setFavoriteVacancy] = useSetFavoriteVacancyMutation();
  const { data: favoriteVacancies } = useGetFavoriteVacanciesQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const tableVacancies = useMemo(() => {
    if (favoriteVacancies?.data) {
      const tableVacancies: TableVacancies[] = favoriteVacancies?.data?.map((vacancy) => ({
        name: vacancy.teamName,
        comment: vacancy.comment,
        payment: vacancy.payment,
        positionName: vacancy.positionName,
        stack: vacancy.stack,
        star: {},
        statusName: vacancy.statusName,
      }));
      return tableVacancies;
    }
    return [];
  }, [favoriteVacancies]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <AdaptiveTableMUI
      columns={{
        name: {
          label: 'Название команды',
          filterInput: true,
          children: (value, row: Vacancy) => <Link to={`/teams/${row.teamId}`}>{value}</Link>,
        },
        positionName: {
          label: 'Роль',
          filterInput: true,
        },
        stack: {
          label: 'Стек',
          filterInput: true,
        },
        payment: {
          label: 'Ставка',
          filterInput: true,
          isSortColumn: true,
          format: (value) => `${value} р/ч`,
        },
        comment: {
          label: 'Комментарий',
          filterInput: true,
        },
        statusName: {
          children: (value: string, row: Vacancy) =>
            value ? (
              value === 'Оффер от исполнителя' || value === 'Оффер от менеджера' ? (
                <StatusLabel color={statusColors.get('На рассмотрении')} text={'На рассмотрении'} />
              ) : (
                <StatusLabel color={statusColors.get(value)} text={value} />
              )
            ) : (
              <CreateVacancyOffer
                vacancyId={row.id}
                receiverId={row.author.userId}
                fromManager={false}
              />
            ),
        },
        star: {
          children: (_, row: Vacancy) => (
            <Star
              checked={true}
              onChange={() =>
                setFavoriteVacancy({ requestBody: { status: false }, vacancyId: row.id }).then(
                  () => {
                    if (favoriteVacancies?.totalCount)
                      dispatch(setVacanciesCount(favoriteVacancies?.totalCount - 1));
                  },
                )
              }
              style={{ marginRight: '1rem' }}
            />
          ),
        },
      }}
      rows={tableVacancies}
      setRequestBody={setRequestBody}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      sortColumn={sortColumn}>
      <TablePaginationMUI
        limit={limit}
        count={favoriteVacancies?.totalCount}
        entityLabel="вакансий"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
};
