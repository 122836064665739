import React from 'react';
import { Button } from '@components/MUI/Button';
import { useAppSelector } from '@hooks/index';
import { RequestItem } from '@store/requests/contracts';
import { selectCurrentUserId } from '@store/user/user.slice';

type RespondButtonProps = {
  row: RequestItem;
  onClick: () => void;
};

export const RespondButton: React.FC<RespondButtonProps> = ({ row, onClick }) => {
  const myId = useAppSelector(selectCurrentUserId);

  if (
    row.statusName === 'Идет поиск' &&
    !row?.managers?.find((manager) => manager.managerId === myId) &&
    row?.customerId !== myId
  )
    return (
      <Button variant="contained" size="small" style={{ marginRight: '1.5rem' }} onClick={onClick}>
        Откликнуться
      </Button>
    );

  return <></>;
};
