import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RequestItem } from '@store/requests/contracts';
import { useRequestBody } from '@hooks/useRequestBody';
import { formatDate } from '@components/ui/formatDate';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { selectCurrentUserId } from '@store/user/user.slice';
import { closeModal } from '@store/ui/ui.slice';
import { CreateRequestButton } from '../components/CreateRequestButton';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { AdaptiveActionButtons } from '@components/adaptives/AdaptiveActionButtons';
import { useNavigateToUserChat } from '@hooks/useNavigateToUserChat';
import {
  useAcceptRequestMutation,
  useDenyRequestMutation,
  useListMyAssignedRequestsQuery,
} from '@store/requests/requests.slice';

export function MyAssignedRequests() {
  const dispatch = useAppDispatch();
  const createChat = useNavigateToUserChat();

  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();
  const myId = useAppSelector(selectCurrentUserId);
  const [denyRequest] = useDenyRequestMutation();
  const [acceptRequest] = useAcceptRequestMutation();

  const { data: fetchedRequests, isLoading } = useListMyAssignedRequestsQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleApprove = (row: RequestItem) => {
    acceptRequest(row.id).finally(() => dispatch(closeModal()));
  };

  const handleDenyRequest = (row: RequestItem) => {
    denyRequest(row.id).finally(() => dispatch(closeModal()));
  };

  const handleSendMessage = (row: RequestItem) => {
    createChat(row.customerId);
  };

  return (
    <AdaptiveTableMUI
      sortOrder={sortOrder}
      sortColumn={sortColumn}
      setRequestBody={setRequestBody}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      isLoading={isLoading}
      columns={{
        name: {
          label: 'Заявка',
          isSortColumn: true,
          filterInput: true,
          children: (value, row: RequestItem) => <Link to={`/requests/${row.id}`}>{value}</Link>,
        },
        actionsRaw: { children: () => <div></div> },
        customerName: {
          label: 'Заказчик',
          children: (value, row) => <Link to={`/userPage/${row.customerId}`}>{value}</Link>,
          isSortColumn: true,
          filterInput: true,
        },
        created: {
          label: 'Дата отправки',
          format: (value) => formatDate(value, 'DD.MM.YYYY'),
          isSortColumn: true,
          filterInput: true,
        },
        changed: {
          label: 'Дата отклика',
          format: (value) => formatDate(value, 'DD.MM.YYYY'),
          isSortColumn: true,
          filterInput: true,
        },
        deadLine: {
          label: 'Дедлайн',
          format: (value) => formatDate(value, 'DD.MM.YYYY'),
          isSortColumn: true,
          filterInput: true,
        },
        budget: {
          label: 'Бюджет',
          isSortColumn: true,
          filterInput: true,
        },
        currency: {
          label: 'Валюта',
          filterInput: true,
          isSortColumn: false,
          format: (value) => `${value}`,
        },
        statusName: {
          label: 'Статус',
          children: (value) => <StatusLabel text={value} color={statusColors.get(value)} />,
          isSortColumn: true,
          filterInput: true,
        },
        actions: {
          children: (value, row: RequestItem) => (
            <AdaptiveActionButtons
              isOnlySend={
                row.statusName === 'На рассмотрении' &&
                row.managers.find((manager) => manager.managerId === myId)?.statusName ===
                  'Запрос от заказчика'
                  ? false
                  : true
              }
              handleApprove={() => handleApprove(row)}
              handleClose={() => handleDenyRequest(row)}
              handleSendMessage={() => handleSendMessage(row)}
              withoutSend={myId === row.customerId ? true : false}
            />
          ),
        },
      }}
      rows={fetchedRequests?.data || []}
      emptyLabel="Заявки отсутствуют"
      additionalElement={<CreateRequestButton />}>
      <TablePaginationMUI
        limit={limit}
        count={fetchedRequests?.totalCount || 0}
        entityLabel="заявок"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
}
