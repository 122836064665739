import React, { useCallback, useState } from 'react';
import { FormWrapper } from '@components/Form/Form';
import { useAppDispatch } from '@hooks/index';
import { Box, Modal } from '@mui/material';
import { closeModal, openModalError } from '@store/ui/ui.slice';
import { AddProjectForm, AddProjectFormProps } from './AddProjectForm';
import { useGetManagerProjectsQuery, useUpdateProjectMutation } from '@store/project/project.slice';

type AddProjectModalProps = {
  teamId: string;
};

export const AddProjectModal: React.FC<AddProjectModalProps> = ({ teamId }) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(true);
  const [updateProject] = useUpdateProjectMutation();
  const { data: projects } = useGetManagerProjectsQuery({});

  const handleSubmit: AddProjectFormProps['submit'] = async (formValues) => {
    try {
      const project = projects?.data?.find((proj) => proj.id === formValues.projectId);
      updateProject({
        id: project?.id || '',
        budget: project?.budget || 0,
        attachments: project?.attachments?.map((at) => at.id) || [],
        deadLine: project?.deadLine?.format('DD.MM.YYYY HH:MM') || '',
        description: project?.description || '',
        name: project?.name || '',
        projectStatusId: project?.projectStatusId || '',
        projectTypeId: project?.projectTypeId || '',
        requirement: project?.requirement || '',
        stack: project?.stack || '',
        task: project?.task || '',
        teamId: teamId || '',
        visibilityStatusId: project?.visibilityStatusId || '',
      }).unwrap();
      dispatch(closeModal());
    } catch (e: any) {
      dispatch(openModalError(e));
    } finally {
      setOpen(false);
    }
  };

  const cancel = useCallback(() => {
    dispatch(closeModal());
    setOpen(false);
  }, [dispatch]);

  return (
    <Modal
      open={open}
      onClose={cancel}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box>
        <FormWrapper label={'Добавление проекта'}>
          <AddProjectForm cancel={cancel} submit={handleSubmit} />
        </FormWrapper>
      </Box>
    </Modal>
  );
};
