import { ApiClient } from '@store/api-client';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { DocumentsResponse } from './contracts';
import { enqueueSnackbar } from 'notistack';

export const getDocsFavorite = async (
  request: Api.operations['getOrderProjects_1']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getOrderProjects_1']['responses']['200']['content']['application/json']
  >(`/api/document/favorite`, request);
  return plainToInstance(DocumentsResponse, data);
};

export const setDocsFavorite = async (
  requestBody: Api.operations['changeFavoriteStatus_5']['requestBody']['content']['application/json'],
  docId: Api.operations['changeFavoriteStatus_5']['parameters']['path']['id'],
) => {
  const { data } = await ApiClient.put<
    Api.operations['changeFavoriteStatus_5']['responses']['200']['content']['application/json']
  >(`/api/file/${docId}/favorite`, requestBody);

  enqueueSnackbar('Избранное документа успешно обновлено', {
    variant: 'success',
  });
  return data;
};

export const getFile = async (key: Api.operations['getFile']['parameters']['path']['key']) => {
  const { data, headers } = await ApiClient.get<
    Api.operations['getFile']['responses']['200']['content']['multipart/byteranges']
  >(`/api/file/${key}`, { responseType: 'blob' });
  return { data, headers };
};
