import React, { useCallback, useEffect } from 'react';
import { useAppDispatch } from '@hooks/index';
import { closeModal, openModal, openModalError } from '@store/ui/ui.slice';
import {
  CreateVacancyForm,
  CreateVacancyProps as CreateVacancyFormProps,
} from '@pages/vacancy/modals/CreateVacancyModal';
import { useCreateVacancyMutation } from '@store/vacancy/vacancy.slice';

type CreateVacancyProps = {
  positionId?: string;
  teamId?: string;
  stack?: string;
};

export const CreateVacancy: React.FC<CreateVacancyProps> = ({ positionId, stack, teamId }) => {
  const dispatch = useAppDispatch();
  const [createVacancy] = useCreateVacancyMutation();
  const submitFormCreateVacancy: CreateVacancyFormProps['submit'] = useCallback(
    async (CreateVacancyForm, form) => {
      try {
        createVacancy({
          name: CreateVacancyForm.name,
          teamId: CreateVacancyForm.teamId,
          teamPositionId: CreateVacancyForm.teamPositionId,
          directionId: CreateVacancyForm.directionId,
          stack: CreateVacancyForm.stack,
          comment: CreateVacancyForm.comment,
          payment: CreateVacancyForm.payment,
          executorId: CreateVacancyForm.executorId,
          open: CreateVacancyForm.open,
        }).then(() => dispatch(closeModal()));
      } catch (e: any) {
        dispatch(openModalError(e));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(
      openModal(
        <CreateVacancyForm
          value={{
            name: '',
            teamId: teamId || '',
            teamPositionId: positionId || '',
            directionId: '',
            stack: stack || '',
            comment: '',
            payment: 0,
            executorId: '',
            open: false,
          }}
          submit={submitFormCreateVacancy}
        />,
      ),
    );
  }, [dispatch, submitFormCreateVacancy]);

  return null;
};
