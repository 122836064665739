import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@components/MUI/Button';
import { Select, SelectOption } from '@components/MUI/Select';
import { Form, FormikBag, useField, useFormikContext, withFormik } from 'formik';
import { object } from 'yup';
import { Project } from '@store/project/contracts';
import styles from './AddProject.module.scss';
import { useGetManagerProjectsQuery } from '@store/project/project.slice';

export type AddProjectValue = {
  projectId: string;
};

export type AddProjectFormProps = {
  submit: (value: AddProjectValue, form: FormikBag<AddProjectFormProps, AddProjectValue>) => void;
  cancel: () => void;
};

const addProjectSchema = object({});

const AddProject: React.FC = () => {
  const form = useFormikContext<AddProjectValue>();
  const [field] = useField('projectId');
  const cancel = form.getFieldProps('cancel').value;
  const [currentProject, setCurrentProject] = useState<Project | null | undefined>(null);
  const { data: projects } = useGetManagerProjectsQuery({});

  const projectsSelectOptions = useMemo(() => {
    if (projects?.data) {
      const projectsSelectOptions = projects?.data?.map((project) => {
        const projectSelectOption: SelectOption = {
          value: project.id,
          label: project.name,
        };
        return projectSelectOption;
      });
      return projectsSelectOptions;
    }
    return [];
  }, [projects]);

  useEffect(() => {
    if (field.value) {
      const project = projects?.data?.find((project) => project.id === field.value);
      setCurrentProject(project);
    }
  }, [field.value]);

  return (
    <Form onSubmit={form.handleSubmit}>
      <Select
        name="projectId"
        values={projectsSelectOptions}
        isOutlined={true}
        label="Проект*"
        className={styles.select__project}
      />
      {currentProject ? (
        <div>
          <div className={styles.project__id}>
            <span className={styles.item__title}>ID проекта</span>
            <span>{currentProject?.id}</span>
          </div>
          <div className={styles.project__middle__block}>
            <div className={styles.project__type}>
              <span className={styles.item__title}>Тип проекта</span>
              <span>{currentProject?.projectTypeName}</span>
            </div>
            <div className={styles.project__deadline}>
              <span className={styles.item__title}>Дедлайн проекта</span>
              <span>{currentProject?.deadLine.format('DD.MM.YYYY')}</span>
            </div>
          </div>
          <div className={styles.project__description}>
            <span className={styles.item__title}>Описание проекта</span>
            <span>{currentProject?.description}</span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="d-flex justify-content-end mt-5">
        <Button className={styles.button__cancel} onClick={cancel}>
          Отменить
        </Button>
        <Button
          disabled={!form.isValid}
          className={styles.button__submit}
          variant="contained"
          type="submit"
          disableElevation={true}>
          Добавить
        </Button>
      </div>
    </Form>
  );
};

export const AddProjectForm = withFormik<AddProjectFormProps, AddProjectValue>({
  displayName: 'AddProjectForm',
  mapPropsToValues: (props) => {
    return {
      cancel: props.cancel,
      projectId: '',
    };
  },
  isInitialValid: false,
  enableReinitialize: true,
  validationSchema: addProjectSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(AddProject);
