import React, { useState } from 'react';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { RequestItem } from '@store/requests/contracts';
import { useRequestBody } from '@hooks/useRequestBody';
import { Link } from 'react-router-dom';
import { formatDate } from '@components/ui/formatDate';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { RenderManagers } from './components/RenderManagers';
import { CreateRequestButton } from './components/CreateRequestButton';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { useListRequestsCreatedByMeQuery } from '@store/requests/requests.slice';

export function RequestsCreatedByMe() {
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const { data: requests, isLoading } = useListRequestsCreatedByMeQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <AdaptiveTableMUI
      sortOrder={sortOrder}
      sortColumn={sortColumn}
      setRequestBody={setRequestBody}
      setSortColumn={setSortColumn}
      setSortOrder={setSortOrder}
      isLoading={isLoading}
      columns={{
        name: {
          label: 'Заявки',
          isSortColumn: true,
          filterInput: true,
          children: (value, row) => <Link to={`/requests/${row.id}`}>{value}</Link>,
        },
        created: {
          label: 'Дата отправки',
          format: (value) => formatDate(value, 'DD.MM.YYYY'),
          isSortColumn: true,
          filterInput: true,
        },
        changed: {
          label: 'Дата отклика',
          format: (value) => formatDate(value, 'DD.MM.YYYY'),
          isSortColumn: true,
          filterInput: true,
        },
        deadLine: {
          label: 'Дедлайн',
          format: (value) => formatDate(value, 'DD.MM.YYYY'),
          isSortColumn: true,
          filterInput: true,
        },
        budget: {
          label: 'Бюджет',
          isSortColumn: true,
          filterInput: true,
        },
        currency: {
          label: 'Валюта',
          filterInput: true,
          isSortColumn: false,
          format: (value) => `${value}`,
        },
        statusName: {
          label: 'Статус',
          children: (value) => <StatusLabel text={value} color={statusColors.get(value)} />,
          isSortColumn: true,
          filterInput: true,
        },
        managers: {
          label: 'Менеджер',
          children: (value, row: RequestItem) => <RenderManagers managers={value} request={row} />,
        },
      }}
      emptyLabel="Заявки отсутствуют"
      rows={requests?.data || []}
      additionalElement={<CreateRequestButton />}>
      <TablePaginationMUI
        limit={limit}
        count={requests?.totalCount || 0}
        entityLabel="заявок"
        page={page}
        handleChangePage={(_, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </AdaptiveTableMUI>
  );
}
