import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as VacancyService from '@store/vacancy/vacancy.service';
import {
  CreatingVacancyRequest,
  CreatingVacancyResponse,
  GetMyOffersResponse,
  GetMyVacanciesResponse,
  GetVacanciesResponse,
  Vacancy,
  VacancyResponse,
} from './contracts';
import { RootState } from '../index';
import { api, apiErrorHandler } from '@store/api-client';
import { enqueueSnackbar } from 'notistack';
import { PathParameters, RequestBody, ResponseBody } from '@store/utility';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';

type VacancyState = {
  vacancies: Vacancy[];
};

const initialState: VacancyState = {
  vacancies: [],
};

type SetActionPayload = {
  vacancies: Vacancy[];
};

export const vacancySlice = createSlice({
  name: 'vacancy',
  initialState,
  reducers: {
    setVacancies: (state, action: PayloadAction<SetActionPayload>) => {
      state.vacancies = [...action.payload.vacancies];
    },
  },
});

export const createVacancy = createAsyncThunk(
  'vacancy/createVacancy',
  async (request: CreatingVacancyRequest, { dispatch }) => {
    try {
      const response = await VacancyService.createVacancy({
        name: request.name,
        teamId: request.teamId,
        teamPositionId: request.teamPositionId,
        directionId: request.directionId,
        stack: request.stack,
        comment: request.comment,
        payment: request.payment,
        executorId: request.executorId,
        open: request.open,
      });
      enqueueSnackbar(`Вакансия успешно создана`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const { setVacancies } = vacancySlice.actions;
export const selectVacancies = (state: RootState) => state.vacancy.vacancies;
export const selectVacancy = createSelector(
  (state: RootState, id: string) => state.vacancy.vacancies.find((v) => v.id === id),
  (vacancy) => vacancy,
);

export const vacancyReducer = vacancySlice.reducer;

export const teamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    setFavoriteVacancy: builder.mutation<
      ResponseBody<Api.operations['changeFavoriteStatus']>,
      {
        requestBody: RequestBody<Api.operations['changeFavoriteStatus']>;
        vacancyId: PathParameters<Api.operations['changeFavoriteStatus'], 'id'>;
      }
    >({
      query: ({ vacancyId, requestBody }) => ({
        method: 'PUT',
        url: `/api/vacancy/${vacancyId}/favorite`,
        body: requestBody,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Избранное вакансии успешно обновлено', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: [
        'FavaoriteVacancies',
        'MyOffers',
        'MyVacancies',
        'VacanciesExecutor',
        'Vacancy',
      ],
    }),

    getVacancy: builder.query<Vacancy, PathParameters<Api.operations['getVacancy'], 'id'>>({
      query: (vacancyId) => ({
        method: 'GET',
        url: `api/vacancy/${vacancyId}`,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getVacancy']>) => {
        return plainToInstance(Vacancy, response);
      },
      providesTags: ['Vacancy'],
    }),

    createVacancy: builder.mutation<CreatingVacancyResponse, CreatingVacancyRequest>({
      query: (requestBody) => ({
        method: 'POST',
        url: 'api/vacancy',
        body: requestBody,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Вакансия успешно создана', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TeamDetail', 'MyVacancies', 'VacanciesExecutor'],
    }),

    getVacancyResponses: builder.query<
      VacancyResponse,
      RequestBody<Api.operations['getManagerOwnVacancies']>
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: '/api/vacancy/manager/own',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getManagerOwnVacancies']>) => {
        return plainToInstance(VacancyResponse, response);
      },
      providesTags: ['VacancyResponses'],
    }),

    getMyVacancies: builder.query<
      GetMyVacanciesResponse,
      RequestBody<Api.operations['getManagerOwnVacancies']>
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: 'api/vacancy/manager',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getManagerOwnVacancies']>) => {
        return plainToInstance(GetMyVacanciesResponse, response);
      },
      providesTags: ['MyVacancies'],
    }),

    getFavoriteVacancies: builder.query<
      GetMyVacanciesResponse,
      RequestBody<Api.operations['getFavoriteVacancies']>
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: '/api/vacancy/favorite',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getFavoriteVacancies']>) => {
        return plainToInstance(GetMyVacanciesResponse, response);
      },
      providesTags: ['FavaoriteVacancies'],
    }),

    getVacancies: builder.query<
      GetVacanciesResponse,
      RequestBody<Api.operations['getExecutorVacancies']>
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: '/api/vacancy/executor',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getExecutorVacancies']>) => {
        return plainToInstance(GetVacanciesResponse, response);
      },
      providesTags: ['VacanciesExecutor'],
    }),

    getMyOffers: builder.query<
      GetMyOffersResponse,
      RequestBody<Api.operations['getExecutorOwnVacancies']>
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: 'api/vacancy/executor/own',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getExecutorOwnVacancies']>) => {
        return plainToInstance(GetMyOffersResponse, response);
      },
      providesTags: ['MyOffers'],
    }),

    editVacancy: builder.mutation<
      ResponseBody<Api.operations['updateVacancy']>,
      RequestBody<Api.operations['updateVacancy']>
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: '/api/vacancy/edit',
        body: requestBody,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Вакансия отредактирована', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: [
        'Vacancy',
        'VacanciesExecutor',
        'VacancyResponses',
        'MyVacancies',
        'DashboardVacanciesAnalytics',
      ],
    }),

    closeVacancy: builder.mutation<
      ResponseBody<Api.operations['closeVacancy']>,
      PathParameters<Api.operations['closeVacancy'], 'id'>
    >({
      query: (id) => ({
        method: 'PATCH',
        url: `/api/vacancy/${id}`,
        body: id,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Вакансия закрыта', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['Vacancy'],
    }),
  }),
});

export const {
  useSetFavoriteVacancyMutation,
  useGetVacancyQuery,
  useCreateVacancyMutation,
  useGetVacancyResponsesQuery,
  useGetMyVacanciesQuery,
  useGetFavoriteVacanciesQuery,
  useGetVacanciesQuery,
  useGetMyOffersQuery,
  useEditVacancyMutation,
  useCloseVacancyMutation,
} = teamApi;
