import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Box, Divider, InputAdornment, Modal, TextField } from '@mui/material';
import { closeModal, isModalOpen } from '@store/ui/ui.slice';
import { FormWrapper } from '@components/Form/Form';
import { Link } from 'react-router-dom';
import { ReactComponent as SearchGray } from '@assets/img/searchGray.svg';
import { debounce } from 'lodash';
import styles from './ProjectsByStatusModal.module.scss';
import { useGetProjectsQuery } from '@store/project/project.slice';

type ProjectsByStatusModalProps = {
  statusName: string;
};

export const ProjectsByStatusModal: React.FC<ProjectsByStatusModalProps> = ({ statusName }) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isModalOpen);
  const [filterValue, setFilterValue] = useState<string>('');
  const { data: projects } = useGetProjectsQuery({
    projectStatusName: {
      value: statusName,
      searchType: 'LIKE',
      sort: false,
    },
    name: {
      value: filterValue,
      searchType: 'LIKE',
      sort: false,
    },
  });

  const handleFilterChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFilterValue(e.target.value);
    },
    500,
  );

  return (
    <Modal open={isOpen} onClose={() => dispatch(closeModal())} className={styles.modal}>
      <Box className={styles.container}>
        <FormWrapper label={`Проекты ${statusName}`} titleClassName={styles.title}>
          <TextField
            size="small"
            placeholder="Поиск"
            className={styles.filter__input}
            onChange={handleFilterChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchGray />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <Divider className={styles.divider} />
          <ul className={styles.projects__list}>
            {projects?.data?.map((project, index, projects) => (
              <>
                <li className={styles.project__item}>
                  <Link
                    to={`/projects/${project.id}/details`}
                    onClick={() => dispatch(closeModal())}>
                    {project.name}
                  </Link>
                </li>
                {index !== projects.length - 1 ? <Divider className={styles.divider} /> : <></>}
              </>
            ))}
          </ul>
        </FormWrapper>
      </Box>
    </Modal>
  );
};
