import { ApiClient } from '@store/api-client';
import {
  GetApprovedRequestsResponse,
  GetRequests,
  PublishRequestResponse,
  RequestDetail,
  RequestItem,
} from '@store/requests/contracts';
import { CreateSaveRequestResponse } from '@store/user/contracts';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { enqueueSnackbar } from 'notistack';

export type ListMyRequestsParams = {
  managerId?: string;
  start?: number;
  limit?: number;
  sortField?: 'created';
  sortOrder?: 'asc' | 'desc';
};

export type TransferToProjectRequest = {
  id: string;
  projectPrefix: string;
  projectName?: string;
};

export const setFavoriteRequest = async (
  requestBody: Api.operations['changeFavoriteStatus_3']['requestBody']['content']['application/json'],
  requestId: Api.operations['changeFavoriteStatus_3']['parameters']['path']['id'],
) => {
  const { data } = await ApiClient.put<
    Api.operations['changeFavoriteStatus_3']['responses']['200']['content']['application/json']
  >(`/api/request/${requestId}/favorite`, requestBody);

  enqueueSnackbar('Избранное заявки успешно обновлено', {
    variant: 'success',
  });
  return data;
};

export const detailRequest = async (id?: string) => {
  const { data } = await ApiClient.get<
    Api.operations['getRequest']['responses']['200']['content']['application/json']
  >(`/api/request/${id}`);
  return plainToInstance(RequestItem, data);
};

export const listRequests = async (
  request: Api.operations['getOpenRequests']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getOpenRequests']['responses']['200']['content']['application/json']
  >(`/api/request/open`, request);
  return plainToInstance(GetRequests, data);
};

export const sendProrosal = async (
  id: Api.operations['createProposal']['parameters']['path']['id'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['createProposal']['responses']['200']['content']['application/json']
  >(`/api/request/proposal/${id}`, id);
  enqueueSnackbar(`Операция выполнена`, {
    variant: 'success',
  });
  return data;
};

export const transferToProject = async (request: TransferToProjectRequest) => {
  try {
    const { data } = await ApiClient.post<
      Api.operations['processRequest']['responses']['200']['content']['application/json']
    >(`/api/request/process/`, request);
    enqueueSnackbar(`Заявка успешно переведена в проект`, {
      variant: 'success',
    });
    return data;
  } catch (e: any) {
    enqueueSnackbar(`${e.message}`, {
      variant: 'error',
    });
  }
};

export const denyRequest = async (
  id: Api.operations['denyProposal']['parameters']['path']['id'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['denyProposal']['responses']['200']['content']['application/json']
  >(`/api/request/proposal/deny/${id}`, id);
  return data;
};

export const acceptRequest = async (
  id: Api.operations['acceptProposal']['parameters']['path']['id'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['acceptProposal']['responses']['200']['content']['application/json']
  >(`/api/request/proposal/accept/${id}`, id);
  return data;
};

export const listMyAssignedRequests = async (
  request: Api.operations['getRequestsForManager']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getRequestsForManager']['responses']['200']['content']['application/json']
  >('api/request/my', request);
  return plainToInstance(GetRequests, data);
};

export const listRequestsCreatedByMe = async (
  request: Api.operations['getRequests']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getRequests']['responses']['200']['content']['application/json']
  >(`api/request/list`, request);
  return plainToInstance(GetRequests, data);
};

export const getFavoriteRequests = async (
  requestBody: Api.operations['getFavoriteRequests']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getFavoriteRequests']['responses']['200']['content']['application/json']
  >('/api/request/favorite', requestBody);
  return plainToInstance(GetRequests, data);
};

export const denyManager = async (request: Api.operations['denyManager']['parameters']['path']) => {
  const { data } = await ApiClient.post<
    Api.operations['denyManager']['responses']['200']['content']['application/json']
  >(`/api/request/deny/${request.id}/${request.managerId}`, request);
  return data;
};

export const acceptManager = async (
  request: Api.operations['acceptManager']['parameters']['path'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['acceptManager']['responses']['200']['content']['application/json']
  >(`/api/request/accept/${request.id}/${request.managerId}`, request);
  return data;
};

export const publishRequest = async (id: string) => {
  const { data } = await ApiClient.post<PublishRequestResponse>(`/api/request/publish/${id}`);
  return data;
};

export const createRequest = async (
  request: Api.operations['saveRequest']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<CreateSaveRequestResponse>('/api/request', request);
  return data;
};

export const updateRequest = async (
  requestBody: Api.operations['changeRequest']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.patch<
    Api.operations['changeRequest']['responses']['200']['content']['application/json']
  >('/api/request', requestBody);
  enqueueSnackbar(`Заявка отредактирована`, {
    variant: 'success',
  });
  return data;
};

export const getApprovedRequests = async (
  request: Api.operations['getAcceptedRequests']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getAcceptedRequests']['responses']['200']['content']['application/json']
  >('/api/request/accepted', request);
  return plainToInstance(GetApprovedRequestsResponse, data);
};

export const detailPageRequest = async (id: string) => {
  const { data } = await ApiClient.get<RequestDetail>(`/api/request/${id}`);
  return data;
};

export const deleteManager = async (requestId: string) => {
  const { data } = await ApiClient.post(`api/request/${requestId}/manager/dismiss`);
  return data;
};
