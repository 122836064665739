import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { CreateRequestProps, EditRequestForm } from './EditRequestForm';
import { useNavigate, useParams } from 'react-router-dom';
import { RequestItem } from '@store/requests/contracts';
import { useAppSelector } from '@hooks/index';
import { selectCurrentUserId } from '@store/user/user.slice';
import dayjs from 'dayjs';
import styles from './EditRequestForm.module.scss';
import { useDetailRequestQuery, useUpdateRequestMutation } from '@store/requests/requests.slice';

export const EditRequest = () => {
  const navigate = useNavigate();
  const { requestId } = useParams();
  const myId = useAppSelector(selectCurrentUserId);
  const [request, setRequest] = useState<RequestItem | null>(null);
  const { data } = useDetailRequestQuery({ id: requestId || '' });
  const [updateRequest] = useUpdateRequestMutation();

  useEffect(() => {
    if (data) {
      setRequest(data);
    }
  }, [data]);

  const submitForm: CreateRequestProps['submit'] = async (formValues, form) => {
    try {
      if (dayjs(formValues.deadLine, 'YYYY-MM-DDTHH:mm').isValid()) {
        formValues.deadLine = dayjs(formValues.deadLine, 'YYYY-MM-DDTHH:mm').format(
          'DD.MM.YYYY HH:mm',
        );
      }
      await updateRequest({
        name: formValues.name,
        deadLine: formValues.deadLine,
        description: formValues.description,
        tag: formValues.tag,
        budget: formValues.budget,
        currencyId: formValues.currencyId,
        stack: formValues.stack,
        requirement: formValues.requirement,
        task: formValues.task,
        headId: formValues.headId,
        managerNeed: !formValues.managerNeed,
        attachments: formValues.attachments,
        projectTypeId: formValues.projectTypeId,
        id: formValues.id,
        visibilityStatusId: formValues.visibilityStatusId,
      });
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    } finally {
      navigate(`/requests/${requestId}`);
    }
  };

  return (
    <Paper elevation={0} variant="outlined" className={styles.page__wrapper}>
      <div className={styles.title__block}>
        <h2 className={styles.title__content}>Редактирование заявки</h2>
      </div>
      <EditRequestForm
        submit={submitForm}
        value={{
          name: request?.name || '',
          deadLine: request?.deadLine?.format('YYYY-MM-DDTHH:MM') || '',
          description: request?.description || '',
          tag: request?.tag || '',
          budget: request?.budget || 0,
          currencyId: request?.currencyId || '',
          url: request?.url || '',
          stack: request?.stack || '',
          requirement: request?.requirement || '',
          task: request?.task || '',
          headId: request?.managers?.length ? request?.managers[0]?.managerId : myId,
          managerNeed:
            request?.managers?.length && request?.managers[0]?.managerId === myId ? true : false,
          attachments: request?.attachments.map((attachment) => attachment.id) || [],
          projectTypeId: request?.projectTypeId || '',
          visibilityStatusId: request?.visibilityStatusId || '',
          id: requestId || '',
        }}
      />
    </Paper>
  );
};
