import { List, styled, useMediaQuery } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { ReactComponent as Cloud } from '@assets/img/cloud.svg';
import { ReactComponent as Dashboard } from '@assets/img/dashboard.svg';
import { ReactComponent as Profile } from '@assets/img/profile.svg';
import { ReactComponent as Application } from '@assets/img/application.svg';
import { ReactComponent as Tasks } from '@assets/img/tasks.svg';
import { ReactComponent as Teams } from '@assets/img/teams.svg';
import { ReactComponent as Vacancies } from '@assets/img/vacancies.svg';
import { ReactComponent as Messages } from '@assets/img/messages.svg';
import { ReactComponent as Balance } from '@assets/img/balance.svg';
import { ReactComponent as Projects } from '@assets/img/projects.svg';
import { ReactComponent as Favorite } from '@assets/img/favorite.svg';
import { useAppSelector } from '@hooks/index';
import { ButtonSidebar } from '@components/Sidebar/ButtonSidebar';
import { selectProfile } from '@store/user/user.slice';
import { Can } from '@access-control';
import { useMemo } from 'react';

export const Sidebar = () => {
  const open = useAppSelector((state) => state.ui.sidebarVisibility);
  const myRoles = useAppSelector(selectProfile)?.roles;
  const isTablet = useMediaQuery('(max-width: 991px)');

  const hasRolesExecutor =
    (myRoles?.length === 2 &&
      myRoles[0].name === 'Пользователь' &&
      myRoles[1].name === 'Исполнитель') ||
    (myRoles?.length === 1 && myRoles[0].name === 'Пользователь');
  const projectRoute = myRoles?.find((role) => role.name === 'Заказчик')
    ? 'customer'
    : myRoles?.find((role) => role.name === 'Менеджер')
    ? 'manager'
    : myRoles?.find((role) => role.name === 'Исполнитель')
    ? 'executor'
    : '';

  const vacanciesRoute = useMemo(() => {
    if (myRoles?.find((role) => role.name === 'Менеджер')) return '/vacancies/my';
    if (myRoles?.find((role) => role.name === 'Исполнитель')) return '/vacancies';
    return '';
  }, [myRoles]);

  const openedMixin = () => ({
    width: '188px',
    transition: 'all 0.5s ease-in-out',
    overflowX: 'hidden',
    zIndex: 5,
  });

  const closedMixin = () => ({
    transition: 'all 0.5s ease-in-out',
    width: isTablet ? '0px' : '82px',
    overflowX: 'hidden',
    zIndex: 5,
  });

  const getSidebarStyles = (open: any) => {
    if (open) {
      return openedMixin();
    } else {
      return closedMixin();
    }
  };
  const Drawer = styled(MuiDrawer)({
    '& .MuiDrawer-paper': {
      borderRight: 'none',
      position: 'sticky',
      ...getSidebarStyles(open),
      '& .MuiDrawer-paper': getSidebarStyles(open),
      transition: 'width 0.5s ease',
      zIndex: 5,
    },
    '&.MuiDrawer-docked.MuiDrawer-root': {
      position: 'sticky',
      top: 0,
    },
  });

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <List style={{ paddingTop: '66px' }}>
          <Can I="read" a="Dashboard">
            <ButtonSidebar href={`/dashboard`} text="Дашборд" open={open}>
              <Dashboard />
            </ButtonSidebar>
          </Can>
          <ButtonSidebar href="/" text="Профиль" open={open}>
            <Profile />
          </ButtonSidebar>
          <Can I="create" a="Task">
            <ButtonSidebar href="/tasks" text="Задачи" open={open}>
              <Tasks />
            </ButtonSidebar>
          </Can>
          <ButtonSidebar href={`/projects/${projectRoute}`} text="Проекты" open={open}>
            <Projects />
          </ButtonSidebar>
          <Can I="read" a="RequestList">
            <ButtonSidebar
              href="/requests"
              text="Заявки"
              open={open}
              style={{ display: hasRolesExecutor ? 'none' : 'block' }}>
              <Application />
            </ButtonSidebar>
          </Can>
          <ButtonSidebar href="/clouds" text="Облачный ДЦ" open={open}>
            <Cloud />
          </ButtonSidebar>
          <Can I="read" a="TeamsTable">
            <ButtonSidebar href="/teams" text="Команды" open={open}>
              <Teams />
            </ButtonSidebar>
          </Can>
          <Can I="read" a="VacanciesSection">
            <ButtonSidebar href={vacanciesRoute} text="Вакансии" open={open}>
              <Vacancies />
            </ButtonSidebar>
          </Can>
          <ButtonSidebar href="/messages" text="Сообщения" open={open}>
            <Messages />
          </ButtonSidebar>
          <Can I="create" a="Requisite">
            <ButtonSidebar href="/balance" text="Баланс" open={open}>
              <Balance />
            </ButtonSidebar>
          </Can>
          <ButtonSidebar href="/favorite" text="Избранное" open={open}>
            <Favorite />
          </ButtonSidebar>
        </List>
      </Drawer>
    </>
  );
};
