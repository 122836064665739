import React, { useCallback, useEffect, useState } from 'react';
import { ParticipantsForm } from './components/ParticipantsForm';
import styles from '@styles/Projects.module.scss';
import { useParams } from 'react-router-dom';
import { AssignTeamModal } from '@pages/project/components/AssignTeam/AssignTeamModal';
import { Managers } from '@pages/project/Managers';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { selectCurrentUserId } from '@store/user/user.slice';
import { useDetailProjectQuery } from '@store/project/project.slice';
import { useLazyDetailRequestQuery } from '@store/requests/requests.slice';
import { RequestItem } from '@store/requests/contracts';
import { closeModal, openModal } from '@store/ui/ui.slice';

export function Participants() {
  const dispatch = useAppDispatch();
  const myId = useAppSelector(selectCurrentUserId);
  const { projectId } = useParams();
  const [request, setRequest] = useState<RequestItem | null>(null);
  const [getRequestDetail] = useLazyDetailRequestQuery();
  const { data: project } = useDetailProjectQuery(projectId || '');

  useEffect(() => {
    getRequestDetail({ id: project?.requestId || '' }).then((response) => {
      if (response.data) setRequest(response?.data);
    });
  }, [project]);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleShow = useCallback(() => {
    dispatch(openModal(<AssignTeamModal onClose={handleClose} projectId={projectId} />));
  }, [dispatch]);

  const submit = () => {};

  if (project)
    return (
      <div>
        <h3 className={styles.title}>{project?.name}</h3>
        <h6 className={styles.commonCardLabel}>Команда</h6>
        {project.teamName && (
          <div className={styles.teamName}>
            <span>{project.teamName}</span>
          </div>
        )}
        {project.teamLeadId === myId ? (
          project.teamId ? (
            <button className={styles.addTeamButton} onClick={handleShow}>
              Поменять команду
            </button>
          ) : (
            <button className={styles.addTeamButton} onClick={handleShow}>
              Назначить команду
            </button>
          )
        ) : (
          <></>
        )}

        <h6 className={styles.commonCardLabel}>Менеджер</h6>
        <Managers managers={request?.managers} />
        <h4 className="mt-4 mb-3">Участники</h4>
        <ParticipantsForm
          value={{ participants: project.teamPositions || [] }}
          submit={submit}
          managerId={project.teamLeadId}
        />

        {project?.tracker !== null &&
        project?.teamPositions?.find((position) => position?.integrations?.length === 0) ? (
          <div className={styles.no__integration__message}>
            {project?.teamPositions?.map((position) =>
              position?.integrations?.includes('YANDEX') ? (
                <></>
              ) : (
                <span>{position?.userName}, </span>
              ),
            )}
            не синхронизировали свой аккаунт с яндекс аккаунтом.
            <br></br>
            Данным пользователям необходимо провести синхронизацию для продолжения работы.
          </div>
        ) : (
          <></>
        )}
        <></>
      </div>
    );

  return <></>;
}
