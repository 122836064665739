import React, { useEffect, useState } from 'react';
import { MainContentLayout } from '@layouts/MainContentLayout';
import { FavoriteNavigationBar } from './FavoriteNavigationBar';
import { Outlet } from 'react-router-dom';
import { useGetFavoriteMessagesQuery } from '@store/messages/messages.service';
import { useAppDispatch } from '@hooks/index';
import { getFavoriteCounts } from '@store/favorite/favorite.slice';

export type SetFavoriteCounts = {
  setMessagesCount: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export const FavoriteLayout: React.FC = () => {
  const dispatsch = useAppDispatch();
  const [messagesCount, setMessagesCount] = useState(0);
  const { data: messagesResponse } = useGetFavoriteMessagesQuery({});

  useEffect(() => {
    dispatsch(getFavoriteCounts());
  }, []);

  useEffect(() => {
    setMessagesCount(messagesResponse?.totalCount || 0);
  }, [messagesResponse]);

  return (
    <MainContentLayout>
      <FavoriteNavigationBar messagesCount={messagesCount || ''} />
      <Outlet context={[{ setMessagesCount: setMessagesCount }]} />
    </MainContentLayout>
  );
};
