import styles from './JobsWithoutRegistration.module.scss';
import { Alert } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import React, { useCallback } from 'react';
import { Search } from '@components/MUI/Search';
import TuneIcon from '@mui/icons-material/Tune';
import { useAppDispatch } from '@hooks/index';
import { FormikHelpers } from 'formik/dist/types';
import { publicVacancy } from '@store/publicVacancy/publicVacancy.slice';

const alertStyles = {
  color: 'var(--text-color-dark-grey)',
  marginBottom: '1.5rem',
  background: 'var(--bg-light-pink)',
  fontSize: '16px',
};

export const JobsWithoutRegistration = () => {
  const dispatch = useAppDispatch();

  const handleSearch = useCallback(
    ({ search }: { search: string }, formikHelpers: FormikHelpers<{ search: string }>) => {
      dispatch(
        publicVacancy({
          start: 0,
          limit: 10,
          positionName: {
            value: search,
            searchType: 'LIKE',
            sort: false,
          },
        }),
      );
    },
    [dispatch],
  );
  return (
    <>
      <div className={styles.search}>
        <div className={styles.containerSearch}>
          <div className={styles.searchBlock}>
            <Search placeholder="Вакансия" handleSearch={handleSearch} />
            <div className={styles.blockTuneIcon}>
              <Link to="/openProjectsFilters">
                <TuneIcon sx={{ color: '#78858B', width: '2rem', height: '2rem' }} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.containerAlert}>
        <div className={styles.mainContainer}>
          <Alert severity="error" style={alertStyles} icon={false}>
            Чтобы увидеть полный список проектов, вам необходимо{' '}
            <Link
              to="/registration"
              className="color-green color-green_hover text-decoration-underline">
              зарегистрироваться
            </Link>
          </Alert>
          <div className={styles.containerHeader}>
            <ul className={styles.header}>
              <li>
                <NavLink
                  to="/openProjectsExecutor"
                  className={({ isActive }) => (isActive ? 'active' : '')}>
                  Вакансии
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/openProjectsCustomer"
                  className={({ isActive }) => (isActive ? 'active' : '')}>
                  Проекты
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
