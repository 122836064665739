import { RequestParams } from '@components/Table/TableHeadCell';
import { useState } from 'react';

export const useRequestBody = (initialRequestBody: Record<string, RequestParams> = {}) => {
  const [requestBody, setRequestBody] = useState<Record<string, RequestParams>>(initialRequestBody);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>(undefined);

  return { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder };
};
