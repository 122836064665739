import { useCallback, useEffect, useMemo, useState } from 'react';
import { Pagination, PaginationQuery } from '@store/common';
import { AxiosError } from 'axios';
import { useAbortController } from '@hooks/useAbortController';

export const usePagination = <T, K>(params: UsePaginationParams<T, K>) => {
  const [start, setStart] = useState(params.start);
  const [limit, setLimit] = useState(params.limit);
  const [sortOrder, setSortOrder] = useState(params.sortOrder);
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [total, setTotal] = useState(0);
  const [accumulate, setAccumulate] = useState(params.accumulate ?? true);
  const [additionalParams, setAdditionalParams] = useState(params.additionalParams);

  useEffect(() => {
    if (params.accumulate && params.accumulate !== accumulate) {
      setAccumulate(params.accumulate);
    }
    setAdditionalParams(params.additionalParams);
    setSortOrder(params.sortOrder);
    setLimit(params.limit);
    setStart(params.start);
  }, [params.sortOrder, params.accumulate, params.limit, params.start, params.additionalParams]);

  const loadPage = useCallback<(query: PaginationQuery) => Promise<Pagination<T>>>(
    (query: PaginationQuery) => {
      setLoading(true);
      return params.action(query).finally(() => {
        setLoading(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.action],
  );

  const loadNextPage = useCallback(() => {
    setStart(start + limit);
  }, [start, limit]);

  useAbortController(
    {
      fetch: async (signal) => {
        loadPage({
          start,
          limit,
          sortOrder,
          ...additionalParams,
        })
          .then((result) => {
            setTotal(result.totalCount);
            setData((prev) => {
              if (accumulate) {
                return [...prev, ...result.data];
              } else {
                return result.data;
              }
            });
            setError(null);
          })
          .catch((error) => {
            setError(error);
          });
      },
    },
    [start, limit, accumulate, additionalParams, params.refetch],
  );

  const hasNextPage = useMemo(() => {
    if (total === 0) return false;
    const current = start + limit;
    return current < total;
  }, [start, limit, total]);

  return {
    hasNextPage,
    loading,
    loadNextPage,
    data,
    total,
    error,
    setStart,
    setLimit,
    setSortOrder,
  };
};

interface UsePaginationParams<T, K> {
  accumulate?: boolean;
  action: (query: PaginationQuery) => Promise<Pagination<T>>;
  start: number;
  limit: number;
  sortOrder?: 'asc' | 'desc';
  additionalParams?: K;
  refetch?: boolean;
}
