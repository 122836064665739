import { useEffect, useRef } from 'react';

export function useScrollToTop() {
  const topRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (topRef && topRef.current) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return topRef;
}
